#main {
  /* position: absolute; */
  top: 20%;
  /* left: 50%; */
  /* margin-top: -100px; */
  /* margin-left: -250px; */
  /* width: 500px; */
  /* height: 200px; */
  border: 4px dashed #fff;
}
#main p {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 170px;
  color: #ffffff;
  font-family: Arial;
}
#main input {
  /* position: absolute; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
#main button {
  margin: 0;
  color: #fff;
  background: #16a085;
  border: none;
  width: 508px;
  height: 35px;
  margin-top: -20px;
  margin-left: -4px;
  border-radius: 4px;
  border-bottom: 4px solid #117a60;
  transition: all 0.2s ease;
  outline: none;
}
#main button:hover {
  background: #149174;
  color: #0c5645;
}
#main button:active {
  border: 0;
}

.image-order {
  /* position: relative; */
  align-items: center;
  justify-content: center;
}
.delete-icon {
  cursor: pointer;
}

.wrapper {
  /* margin: 20% auto; */
  max-width: 1100px;
  align-items: center;
  justify-content: center;
  display: table;
}

div.galleryUploader {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 200px;
}

div.galleryUploader:hover {
  border: 1px solid #777;
}

div.galleryUploader img {
  width: 100%;
  height: 140px;
}

div.desc {
  padding: 15px;
  text-align: center;
}

.error-msg {
  color: salmon;
}
