:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e9036e;
  --red: #dc3545;
  --orange: #e9036e;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #e9036e;
  --secondary: #6c757d;
  --success: #60a662;
  --info: #1ab3e8;
  --warning: #e9036e;
  --danger: #e25a5a;
  --light: #f8f9fa;
  --dark: #222;
  --success-light: #1cd066;
  --black: #000;
  --dark-light: #262a2e;
  --stone: #232629;
  --gray-color: #969696;
  --gray-dark: #565b61;
  --smoke: #f5f5f5;
  --off-white: #dedede;
  --light-gray: #eee;
  --facebook: #6684c4;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --hoovercolor:#f77500;
  --hhoverbordercolor:#ea6f00;
  --primarydarker:#be1665;
  --colorA:#9c1152;
  --colorB:#e8d21a;
  --colorC:#e9036e;
  --colorD:#1acbe8;
  --colorE:#09879c;

  /* --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace; */
}
*, 
::before,
::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  margin: 0;
  font-family: montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #e9036e;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: var(--primarydarker);
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono,
    courier new, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 1.88125rem;
}
h2,
.h2 {
  font-size: 1.6275rem;
}
h3,
.h3 {
  font-size: 1.25125rem;
}
h4,
.h4 {
  font-size: 1.12875rem;
}
h5,
.h5 {
  font-size: 1.00625rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 2.85rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
small,
.small {
  font-size: 83%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote-footer {
  display: block;
  font-size: 83%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.containerPublic,
.containerPublic-fluid,
.containerPublic-sm,
.containerPublic-md,
.containerPublic-lg,
.containerPublic-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .containerPublic,
  .containerPublic-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .containerPublic,
  .containerPublic-sm,
  .containerPublic-md {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .containerPublic,
  .containerPublic-sm,
  .containerPublic-md,
  .containerPublic-lg {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .containerPublic,
  .containerPublic-sm,
  .containerPublic-md,
  .containerPublic-lg,
  .containerPublic-xl {
    max-width: 1170px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: #fff9f4;
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffdec0;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #e9036e;
}
.table-hover .table-primary:hover {
  background-color: #ffd1a7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffd1a7;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2e6d3;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #acd1ad;
}
.table-hover .table-success:hover {
  background-color: #c2ddc3;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c2ddc3;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfeaf9;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #88d7f3;
}
.table-hover .table-info:hover {
  background-color: #a8e2f7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a8e2f7;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefb8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fee27b;
}
.table-hover .table-warning:hover {
  background-color: #ffe99f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe99f;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7d1d1;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f0a9a9;
}
.table-hover .table-danger:hover {
  background-color: #f3bbbb;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f3bbbb;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c1c1;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8c8c;
}
.table-hover .table-dark:hover {
  background-color: #b4b4b4;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b4b4;
}
.table-success-light,
.table-success-light > th,
.table-success-light > td {
  background-color: #bff2d4;
}
.table-success-light th,
.table-success-light td,
.table-success-light thead th,
.table-success-light tbody + tbody {
  border-color: #89e7af;
}
.table-hover .table-success-light:hover {
  background-color: #aaeec6;
}
.table-hover .table-success-light:hover > td,
.table-hover .table-success-light:hover > th {
  background-color: #aaeec6;
}
.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}
.table-hover .table-black:hover {
  background-color: #ababab;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}
.table-dark-light,
.table-dark-light > th,
.table-dark-light > td {
  background-color: #c2c3c4;
}
.table-dark-light th,
.table-dark-light td,
.table-dark-light thead th,
.table-dark-light tbody + tbody {
  border-color: #8e9092;
}
.table-hover .table-dark-light:hover {
  background-color: #b5b6b7;
}
.table-hover .table-dark-light:hover > td,
.table-hover .table-dark-light:hover > th {
  background-color: #b5b6b7;
}
.table-stone,
.table-stone > th,
.table-stone > td {
  background-color: #c1c2c3;
}
.table-stone th,
.table-stone td,
.table-stone thead th,
.table-stone tbody + tbody {
  border-color: #8d8e90;
}
.table-hover .table-stone:hover {
  background-color: #b4b5b6;
}
.table-hover .table-stone:hover > td,
.table-hover .table-stone:hover > th {
  background-color: #b4b5b6;
}
.table-gray-color,
.table-gray-color > th,
.table-gray-color > td {
  background-color: #e2e2e2;
}
.table-gray-color th,
.table-gray-color td,
.table-gray-color thead th,
.table-gray-color tbody + tbody {
  border-color: #c8c8c8;
}
.table-hover .table-gray-color:hover {
  background-color: #d5d5d5;
}
.table-hover .table-gray-color:hover > td,
.table-hover .table-gray-color:hover > th {
  background-color: #d5d5d5;
}
.table-gray-dark,
.table-gray-dark > th,
.table-gray-dark > td {
  background-color: #d0d1d3;
}
.table-gray-dark th,
.table-gray-dark td,
.table-gray-dark thead th,
.table-gray-dark tbody + tbody {
  border-color: #a7aaad;
}
.table-hover .table-gray-dark:hover {
  background-color: #c3c4c7;
}
.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: #c3c4c7;
}
.table-smoke,
.table-smoke > th,
.table-smoke > td {
  background-color: #fcfcfc;
}
.table-smoke th,
.table-smoke td,
.table-smoke thead th,
.table-smoke tbody + tbody {
  border-color: #fafafa;
}
.table-hover .table-smoke:hover {
  background-color: #efefef;
}
.table-hover .table-smoke:hover > td,
.table-hover .table-smoke:hover > th {
  background-color: #efefef;
}
.table-off-white,
.table-off-white > th,
.table-off-white > td {
  background-color: #f6f6f6;
}
.table-off-white th,
.table-off-white td,
.table-off-white thead th,
.table-off-white tbody + tbody {
  border-color: #eee;
}
.table-hover .table-off-white:hover {
  background-color: #e9e9e9;
}
.table-hover .table-off-white:hover > td,
.table-hover .table-off-white:hover > th {
  background-color: #e9e9e9;
}
.table-light-gray,
.table-light-gray > th,
.table-light-gray > td {
  background-color: #fafafa;
}
.table-light-gray th,
.table-light-gray td,
.table-light-gray thead th,
.table-light-gray tbody + tbody {
  border-color: #f6f6f6;
}
.table-hover .table-light-gray:hover {
  background-color: #ededed;
}
.table-hover .table-light-gray:hover > td,
.table-hover .table-light-gray:hover > th {
  background-color: #ededed;
}
.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #d4ddee;
}
.table-facebook th,
.table-facebook td,
.table-facebook thead th,
.table-facebook tbody + tbody {
  border-color: #afbfe0;
}
.table-hover .table-facebook:hover {
  background-color: #c2cfe7;
}
.table-hover .table-facebook:hover > td,
.table-hover .table-facebook:hover > th {
  background-color: #c2cfe7;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: #fff9f4;
}
.table-hover .table-active:hover {
  background-color: #ffebdb;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #ffebdb;
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffcc9e;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.7rem + 1px);
  padding-bottom: calc(0.7rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.7rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding: 0.4rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1.6rem + 2px);
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
select.form-control[size],
select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #60a662;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(96, 166, 98, 0.9);
  border-radius: 0.25rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #60a662;
  padding-right: calc(1.5em + 1.4rem);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyM2MGE2NjInIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.35rem) center;
  background-size: calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.4rem);
  background-position: top calc(0.375em + 0.35rem) right calc(0.375em + 0.35rem);
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #60a662;
  padding-right: calc(0.75em + 2.8rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2360a662' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.7rem)
      calc(0.75em + 0.7rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #60a662;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #60a662;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #60a662;
}
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #81b882;
  background-color: #81b882;
}
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #60a662;
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #60a662;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #e25a5a;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(226, 90, 90, 0.9);
  border-radius: 0.25rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e25a5a;
  padding-right: calc(1.5em + 1.4rem);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZTI1YTVhJyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2UyNWE1YScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.35rem) center;
  background-size: calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.4rem);
  background-position: top calc(0.375em + 0.35rem) right calc(0.375em + 0.35rem);
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e25a5a;
  padding-right: calc(0.75em + 2.8rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e25a5a' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e25a5a' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.7rem)
      calc(0.75em + 0.7rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e25a5a;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e25a5a;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e25a5a;
}
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea8585;
  background-color: #ea8585;
}
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #e25a5a;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e25a5a;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6rem 1.8125rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  transition: all 0.3s;
}
.dropdownItem:hover {
  color: #f77500 !important;
}
.dropdown-button2 {
  background-color: #212529;
  text-decoration: none;
  border-color:#212529 ;
  
}
.dropdown-button2:hover {
  color: #fff;
  background-color: var(--primarydarker);
  border-color: var(--primarydarker);
}
.dropdown-button2.active {
  background-color: var(--primarydarker) !important;
}
.dropdown-button2:focus,
.dropdown-button2.focus {
  color: #fff;
  background-color: var(--primarydarker);
  border-color: var(--primarydarker);
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}
.dropdown-button2.disabled,
.dropdown-button2:disabled {
  color: #212529;
  background-color: #e9036e;
  border-color: #e9036e;
}
.dropdown-button2:not(:disabled):not(.disabled):active,
.dropdown-button2:not(:disabled):not(.disabled).active,
.show > .dropdown-button2.dropdown-toggle {
  color: #fff;
  background-color: #ea6f00;
  border-color: #dd6900;
}
.dropdown-button2:not(:disabled):not(.disabled):active:focus,
.dropdown-button2:not(:disabled):not(.disabled).active:focus,
.show > .dropdown-button2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #212529;
  background-color: #e9036e;
  border-color: #e9036e;
}
.btn-primary:hover {
  color: #fff;
  background-color: var(--primarydarker);
  border-color: var(--primarydarker);
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: var(--primarydarker);
  border-color: var(--primarydarker);
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #212529;
  background-color: #e9036e;
  border-color: #e9036e;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primarydarker);
  border-color: var(--primarydarker);
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}
.btn-success:hover {
  color: #fff;
  background-color: #508f52;
  border-color: #4c874d;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #508f52;
  border-color: #4c874d;
  box-shadow: 0 0 0 0.2rem rgba(120, 179, 122, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4c874d;
  border-color: #477f49;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 179, 122, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}
.btn-info:hover {
  color: #fff;
  background-color: #1499c8;
  border-color: #1391bc;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #1499c8;
  border-color: #1391bc;
  box-shadow: 0 0 0 0.2rem rgba(60, 190, 235, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1391bc;
  border-color: #1288b1;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 190, 235, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}
.btn-warning:hover {
  color: #212529;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #d8a901;
  border-color: #cb9f01;
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 7, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #cb9f01;
  border-color: #bf9501;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 7, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}
.btn-danger:hover {
  color: #fff;
  background-color: #dc3939;
  border-color: #da2f2f;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #dc3939;
  border-color: #da2f2f;
  box-shadow: 0 0 0 0.2rem rgba(230, 115, 115, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #da2f2f;
  border-color: #d72626;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 115, 115, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-dark:hover {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #090909;
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #090909;
  border-color: #020202;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-success-light {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}
.btn-success-light:hover {
  color: #fff;
  background-color: #17ae55;
  border-color: #16a350;
}
.btn-success-light:focus,
.btn-success-light.focus {
  color: #fff;
  background-color: #17ae55;
  border-color: #16a350;
  box-shadow: 0 0 0 0.2rem rgba(62, 215, 125, 0.5);
}
.btn-success-light.disabled,
.btn-success-light:disabled {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}
.btn-success-light:not(:disabled):not(.disabled):active,
.btn-success-light:not(:disabled):not(.disabled).active,
.show > .btn-success-light.dropdown-toggle {
  color: #fff;
  background-color: #16a350;
  border-color: #14984a;
}
.btn-success-light:not(:disabled):not(.disabled):active:focus,
.btn-success-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-success-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 215, 125, 0.5);
}
.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:focus,
.btn-black.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-dark-light {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}
.btn-dark-light:hover {
  color: #fff;
  background-color: #151719;
  border-color: #0f1112;
}
.btn-dark-light:focus,
.btn-dark-light.focus {
  color: #fff;
  background-color: #151719;
  border-color: #0f1112;
  box-shadow: 0 0 0 0.2rem rgba(71, 74, 77, 0.5);
}
.btn-dark-light.disabled,
.btn-dark-light:disabled {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}
.btn-dark-light:not(:disabled):not(.disabled):active,
.btn-dark-light:not(:disabled):not(.disabled).active,
.show > .btn-dark-light.dropdown-toggle {
  color: #fff;
  background-color: #0f1112;
  border-color: #090a0b;
}
.btn-dark-light:not(:disabled):not(.disabled):active:focus,
.btn-dark-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 74, 77, 0.5);
}
.btn-stone {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}
.btn-stone:hover {
  color: #fff;
  background-color: #111314;
  border-color: #0c0d0d;
}
.btn-stone:focus,
.btn-stone.focus {
  color: #fff;
  background-color: #111314;
  border-color: #0c0d0d;
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 73, 0.5);
}
.btn-stone.disabled,
.btn-stone:disabled {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}
.btn-stone:not(:disabled):not(.disabled):active,
.btn-stone:not(:disabled):not(.disabled).active,
.show > .btn-stone.dropdown-toggle {
  color: #fff;
  background-color: #0c0d0d;
  border-color: #060607;
}
.btn-stone:not(:disabled):not(.disabled):active:focus,
.btn-stone:not(:disabled):not(.disabled).active:focus,
.show > .btn-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 73, 0.5);
}
.btn-gray-color {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}
.btn-gray-color:hover {
  color: #fff;
  background-color: #838383;
  border-color: #7d7d7d;
}
.btn-gray-color:focus,
.btn-gray-color.focus {
  color: #fff;
  background-color: #838383;
  border-color: #7d7d7d;
  box-shadow: 0 0 0 0.2rem rgba(132, 133, 134, 0.5);
}
.btn-gray-color.disabled,
.btn-gray-color:disabled {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}
.btn-gray-color:not(:disabled):not(.disabled):active,
.btn-gray-color:not(:disabled):not(.disabled).active,
.show > .btn-gray-color.dropdown-toggle {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #767676;
}
.btn-gray-color:not(:disabled):not(.disabled):active:focus,
.btn-gray-color:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 133, 134, 0.5);
}
.btn-gray-dark {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}
.btn-gray-dark:hover {
  color: #fff;
  background-color: #44484d;
  border-color: #3e4246;
}
.btn-gray-dark:focus,
.btn-gray-dark.focus {
  color: #fff;
  background-color: #44484d;
  border-color: #3e4246;
  box-shadow: 0 0 0 0.2rem rgba(111, 116, 121, 0.5);
}
.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}
.btn-gray-dark:not(:disabled):not(.disabled):active,
.btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #3e4246;
  border-color: #383b3f;
}
.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 116, 121, 0.5);
}
.btn-smoke {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-smoke:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #dcdcdc;
}
.btn-smoke:focus,
.btn-smoke.focus {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #dcdcdc;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-smoke.disabled,
.btn-smoke:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-smoke:not(:disabled):not(.disabled):active,
.btn-smoke:not(:disabled):not(.disabled).active,
.show > .btn-smoke.dropdown-toggle {
  color: #212529;
  background-color: #dcdcdc;
  border-color: #d5d5d5;
}
.btn-smoke:not(:disabled):not(.disabled):active:focus,
.btn-smoke:not(:disabled):not(.disabled).active:focus,
.show > .btn-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-off-white {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}
.btn-off-white:hover {
  color: #212529;
  background-color: #cbcbcb;
  border-color: #c5c5c5;
}
.btn-off-white:focus,
.btn-off-white.focus {
  color: #212529;
  background-color: #cbcbcb;
  border-color: #c5c5c5;
  box-shadow: 0 0 0 0.2rem rgba(194, 194, 195, 0.5);
}
.btn-off-white.disabled,
.btn-off-white:disabled {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}
.btn-off-white:not(:disabled):not(.disabled):active,
.btn-off-white:not(:disabled):not(.disabled).active,
.show > .btn-off-white.dropdown-toggle {
  color: #212529;
  background-color: #c5c5c5;
  border-color: #bebebe;
}
.btn-off-white:not(:disabled):not(.disabled):active:focus,
.btn-off-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-off-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 194, 195, 0.5);
}
.btn-light-gray {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}
.btn-light-gray:hover {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d5d5d5;
}
.btn-light-gray:focus,
.btn-light-gray.focus {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d5d5d5;
  box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}
.btn-light-gray.disabled,
.btn-light-gray:disabled {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}
.btn-light-gray:not(:disabled):not(.disabled):active,
.btn-light-gray:not(:disabled):not(.disabled).active,
.show > .btn-light-gray.dropdown-toggle {
  color: #212529;
  background-color: #d5d5d5;
  border-color: #cecece;
}
.btn-light-gray:not(:disabled):not(.disabled):active:focus,
.btn-light-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}
.btn-facebook {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #4a6eb9;
  border-color: #4568b2;
}
.btn-facebook:focus,
.btn-facebook.focus {
  color: #fff;
  background-color: #4a6eb9;
  border-color: #4568b2;
  box-shadow: 0 0 0 0.2rem rgba(125, 150, 205, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #4568b2;
  border-color: #4162a9;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 150, 205, 0.5);
}
.btn-outline-primary {
  color: #e9036e;
  border-color: #e9036e;
}
.btn-outline-primary:hover {
  color: #212529;
  background-color: #e9036e;
  border-color: #e9036e;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #e9036e;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #e9036e;
  border-color: #e9036e;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #60a662;
  border-color: #60a662;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #60a662;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}
.btn-outline-info {
  color: #1ab3e8;
  border-color: #1ab3e8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #1ab3e8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}
.btn-outline-warning {
  color: #fec701;
  border-color: #fec701;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fec701;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}
.btn-outline-danger {
  color: #e25a5a;
  border-color: #e25a5a;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e25a5a;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #222;
  border-color: #222;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #222;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-success-light {
  color: #1cd066;
  border-color: #1cd066;
}
.btn-outline-success-light:hover {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}
.btn-outline-success-light:focus,
.btn-outline-success-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}
.btn-outline-success-light.disabled,
.btn-outline-success-light:disabled {
  color: #1cd066;
  background-color: transparent;
}
.btn-outline-success-light:not(:disabled):not(.disabled):active,
.btn-outline-success-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-success-light.dropdown-toggle {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}
.btn-outline-success-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}
.btn-outline-black {
  color: #000;
  border-color: #000;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:focus,
.btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark-light {
  color: #262a2e;
  border-color: #262a2e;
}
.btn-outline-dark-light:hover {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}
.btn-outline-dark-light:focus,
.btn-outline-dark-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}
.btn-outline-dark-light.disabled,
.btn-outline-dark-light:disabled {
  color: #262a2e;
  background-color: transparent;
}
.btn-outline-dark-light:not(:disabled):not(.disabled):active,
.btn-outline-dark-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-light.dropdown-toggle {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}
.btn-outline-dark-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}
.btn-outline-stone {
  color: #232629;
  border-color: #232629;
}
.btn-outline-stone:hover {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}
.btn-outline-stone:focus,
.btn-outline-stone.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}
.btn-outline-stone.disabled,
.btn-outline-stone:disabled {
  color: #232629;
  background-color: transparent;
}
.btn-outline-stone:not(:disabled):not(.disabled):active,
.btn-outline-stone:not(:disabled):not(.disabled).active,
.show > .btn-outline-stone.dropdown-toggle {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}
.btn-outline-stone:not(:disabled):not(.disabled):active:focus,
.btn-outline-stone:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}
.btn-outline-gray-color {
  color: #969696;
  border-color: #969696;
}
.btn-outline-gray-color:hover {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}
.btn-outline-gray-color:focus,
.btn-outline-gray-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}
.btn-outline-gray-color.disabled,
.btn-outline-gray-color:disabled {
  color: #969696;
  background-color: transparent;
}
.btn-outline-gray-color:not(:disabled):not(.disabled):active,
.btn-outline-gray-color:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-color.dropdown-toggle {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}
.btn-outline-gray-color:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-color:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}
.btn-outline-gray-dark {
  color: #565b61;
  border-color: #565b61;
}
.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}
.btn-outline-gray-dark:focus,
.btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}
.btn-outline-gray-dark.disabled,
.btn-outline-gray-dark:disabled {
  color: #565b61;
  background-color: transparent;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active,
.btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}
.btn-outline-smoke {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-smoke:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-smoke:focus,
.btn-outline-smoke.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-smoke.disabled,
.btn-outline-smoke:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-smoke:not(:disabled):not(.disabled):active,
.btn-outline-smoke:not(:disabled):not(.disabled).active,
.show > .btn-outline-smoke.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-smoke:not(:disabled):not(.disabled):active:focus,
.btn-outline-smoke:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-off-white {
  color: #dedede;
  border-color: #dedede;
}
.btn-outline-off-white:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}
.btn-outline-off-white:focus,
.btn-outline-off-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}
.btn-outline-off-white.disabled,
.btn-outline-off-white:disabled {
  color: #dedede;
  background-color: transparent;
}
.btn-outline-off-white:not(:disabled):not(.disabled):active,
.btn-outline-off-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-off-white.dropdown-toggle {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}
.btn-outline-off-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-off-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-off-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}
.btn-outline-light-gray {
  color: #eee;
  border-color: #eee;
}
.btn-outline-light-gray:hover {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}
.btn-outline-light-gray:focus,
.btn-outline-light-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.btn-outline-light-gray.disabled,
.btn-outline-light-gray:disabled {
  color: #eee;
  background-color: transparent;
}
.btn-outline-light-gray:not(:disabled):not(.disabled):active,
.btn-outline-light-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-gray.dropdown-toggle {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}
.btn-outline-light-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-light-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.btn-outline-facebook {
  color: #6684c4;
  border-color: #6684c4;
}
.btn-outline-facebook:hover {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}
.btn-outline-facebook:focus,
.btn-outline-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}
.btn-outline-facebook.disabled,
.btn-outline-facebook:disabled {
  color: #6684c4;
  background-color: transparent;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active,
.btn-outline-facebook:not(:disabled):not(.disabled).active,
.show > .btn-outline-facebook.dropdown-toggle {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active:focus,
.btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #e9036e;
  text-decoration: none;
}
.btn-link:hover {
  color: #d16300;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.8rem 1.6rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.4rem 1.45rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e9036e;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 1.35938rem;
  padding-left: 1.35938rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.0875rem;
  padding-left: 1.0875rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.7rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.6rem + 2px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8rem + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #e9036e;
  background-color: #e9036e;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffcc9e;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffe7d1;
  border-color: #ffe7d1;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+);
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #e9036e;
  background-color: #e9036e;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzQnIHZpZXdCb3g9JzAgMCA0IDQnPjxwYXRoIHN0cm9rZT0nI2ZmZicgZD0nTTAgMmg0Jy8+PC9zdmc+);
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgdmlld0JveD0nLTQgLTQgOCA4Jz48Y2lyY2xlIHI9JzMnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 1.75rem 0.7rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #ffcc9e;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.7rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1.6rem + 2px);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  font-size: 0.875rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #ffcc9e;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.4rem);
  padding: 0.7rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e9036e;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #ffe7d1;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e9036e;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #ffe7d1;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #e9036e;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #ffe7d1;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e9036e;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .containerPublic,
.navbar .containerPublic-fluid,
.navbar .containerPublic-sm,
.navbar .containerPublic-md,
.navbar .containerPublic-lg,
.navbar .containerPublic-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .containerPublic,
  .navbar-expand-sm > .containerPublic-fluid,
  .navbar-expand-sm > .containerPublic-sm,
  .navbar-expand-sm > .containerPublic-md,
  .navbar-expand-sm > .containerPublic-lg,
  .navbar-expand-sm > .containerPublic-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .containerPublic,
  .navbar-expand-sm > .containerPublic-fluid,
  .navbar-expand-sm > .containerPublic-sm,
  .navbar-expand-sm > .containerPublic-md,
  .navbar-expand-sm > .containerPublic-lg,
  .navbar-expand-sm > .containerPublic-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .containerPublic,
  .navbar-expand-md > .containerPublic-fluid,
  .navbar-expand-md > .containerPublic-sm,
  .navbar-expand-md > .containerPublic-md,
  .navbar-expand-md > .containerPublic-lg,
  .navbar-expand-md > .containerPublic-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .containerPublic,
  .navbar-expand-md > .containerPublic-fluid,
  .navbar-expand-md > .containerPublic-sm,
  .navbar-expand-md > .containerPublic-md,
  .navbar-expand-md > .containerPublic-lg,
  .navbar-expand-md > .containerPublic-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .containerPublic,
  .navbar-expand-lg > .containerPublic-fluid,
  .navbar-expand-lg > .containerPublic-sm,
  .navbar-expand-lg > .containerPublic-md,
  .navbar-expand-lg > .containerPublic-lg,
  .navbar-expand-lg > .containerPublic-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .containerPublic,
  .navbar-expand-lg > .containerPublic-fluid,
  .navbar-expand-lg > .containerPublic-sm,
  .navbar-expand-lg > .containerPublic-md,
  .navbar-expand-lg > .containerPublic-lg,
  .navbar-expand-lg > .containerPublic-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .containerPublic,
  .navbar-expand-xl > .containerPublic-fluid,
  .navbar-expand-xl > .containerPublic-sm,
  .navbar-expand-xl > .containerPublic-md,
  .navbar-expand-xl > .containerPublic-lg,
  .navbar-expand-xl > .containerPublic-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .containerPublic,
  .navbar-expand-xl > .containerPublic-fluid,
  .navbar-expand-xl > .containerPublic-sm,
  .navbar-expand-xl > .containerPublic-md,
  .navbar-expand-xl > .containerPublic-lg,
  .navbar-expand-xl > .containerPublic-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .containerPublic,
.navbar-expand > .containerPublic-fluid,
.navbar-expand > .containerPublic-sm,
.navbar-expand > .containerPublic-md,
.navbar-expand > .containerPublic-lg,
.navbar-expand > .containerPublic-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .containerPublic,
.navbar-expand > .containerPublic-fluid,
.navbar-expand > .containerPublic-sm,
.navbar-expand > .containerPublic-md,
.navbar-expand > .containerPublic-lg,
.navbar-expand > .containerPublic-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDMwIDMwJz48cGF0aCBzdHJva2U9J3JnYmEoMCwgMCwgMCwgMC41KScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIHN0cm9rZS13aWR0aD0nMicgZD0nTTQgN2gyMk00IDE1aDIyTTQgMjNoMjInLz48L3N2Zz4=);
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDMwIDMwJz48cGF0aCBzdHJva2U9J3JnYmEoMjU1LCAyNTUsIDI1NSwgMC41KScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIHN0cm9rZS13aWR0aD0nMicgZD0nTTQgN2gyMk00IDE1aDIyTTQgMjNoMjInLz48L3N2Zz4=);
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}
.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.06);
  border-bottom: 0 solid transparent;
}
.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.06);
  border-top: 0 solid transparent;
}
.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
}
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.8rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #222;
  background-color: transparent;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #e9036e;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #e9036e;
  background-color: #e9036e;
  border-color: transparent;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #212529;
  background-color: #e9036e;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #212529;
  background-color: #ea6f00;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #60a662;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #4c874d;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #1ab3e8;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #1391bc;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #fec701;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #cb9f01;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #e25a5a;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #da2f2f;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #222;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #090909;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.badge-success-light {
  color: #fff;
  background-color: #1cd066;
}
a.badge-success-light:hover,
a.badge-success-light:focus {
  color: #fff;
  background-color: #16a350;
}
a.badge-success-light:focus,
a.badge-success-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}
.badge-black {
  color: #fff;
  background-color: #000;
}
a.badge-black:hover,
a.badge-black:focus {
  color: #fff;
  background-color: #000;
}
a.badge-black:focus,
a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.badge-dark-light {
  color: #fff;
  background-color: #262a2e;
}
a.badge-dark-light:hover,
a.badge-dark-light:focus {
  color: #fff;
  background-color: #0f1112;
}
a.badge-dark-light:focus,
a.badge-dark-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}
.badge-stone {
  color: #fff;
  background-color: #232629;
}
a.badge-stone:hover,
a.badge-stone:focus {
  color: #fff;
  background-color: #0c0d0d;
}
a.badge-stone:focus,
a.badge-stone.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}
.badge-gray-color {
  color: #212529;
  background-color: #969696;
}
a.badge-gray-color:hover,
a.badge-gray-color:focus {
  color: #212529;
  background-color: #7d7d7d;
}
a.badge-gray-color:focus,
a.badge-gray-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}
.badge-gray-dark {
  color: #fff;
  background-color: #565b61;
}
a.badge-gray-dark:hover,
a.badge-gray-dark:focus {
  color: #fff;
  background-color: #3e4246;
}
a.badge-gray-dark:focus,
a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}
.badge-smoke {
  color: #212529;
  background-color: #f5f5f5;
}
a.badge-smoke:hover,
a.badge-smoke:focus {
  color: #212529;
  background-color: #dcdcdc;
}
a.badge-smoke:focus,
a.badge-smoke.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.badge-off-white {
  color: #212529;
  background-color: #dedede;
}
a.badge-off-white:hover,
a.badge-off-white:focus {
  color: #212529;
  background-color: #c5c5c5;
}
a.badge-off-white:focus,
a.badge-off-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}
.badge-light-gray {
  color: #212529;
  background-color: #eee;
}
a.badge-light-gray:hover,
a.badge-light-gray:focus {
  color: #212529;
  background-color: #d5d5d5;
}
a.badge-light-gray:focus,
a.badge-light-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.badge-facebook {
  color: #fff;
  background-color: #6684c4;
}
a.badge-facebook:hover,
a.badge-facebook:focus {
  color: #fff;
  background-color: #4568b2;
}
a.badge-facebook:focus,
a.badge-facebook.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #c26817;
  background-color: #ffe7d2;
  border-color: #ffdec0;
}
.alert-primary hr {
  border-top-color: #ffd1a7;
}
.alert-primary .alert-link {
  color: #945012;
}
.alert-secondary {
  color: #52595f;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #3a3f44;
}
.alert-success {
  color: #497e4a;
  background-color: #dfede0;
  border-color: #d2e6d3;
}
.alert-success hr {
  border-top-color: #c2ddc3;
}
.alert-success .alert-link {
  color: #365e37;
}
.alert-info {
  color: #1488b0;
  background-color: #d1f0fa;
  border-color: #bfeaf9;
}
.alert-info hr {
  border-top-color: #a8e2f7;
}
.alert-info .alert-link {
  color: #0f6582;
}
.alert-warning {
  color: #c19701;
  background-color: #fff4cc;
  border-color: #ffefb8;
}
.alert-warning hr {
  border-top-color: #ffe99f;
}
.alert-warning .alert-link {
  color: #8e6f01;
}
.alert-danger {
  color: #ac4444;
  background-color: #f9dede;
  border-color: #f7d1d1;
}
.alert-danger hr {
  border-top-color: #f3bbbb;
}
.alert-danger .alert-link {
  color: #873636;
}
.alert-light {
  color: #bcbdbe;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #a2a4a5;
}
.alert-dark {
  color: #1a1a1a;
  background-color: #d3d3d3;
  border-color: #c1c1c1;
}
.alert-dark hr {
  border-top-color: #b4b4b4;
}
.alert-dark .alert-link {
  color: #010101;
}
.alert-success-light {
  color: #159e4e;
  background-color: #d2f6e0;
  border-color: #bff2d4;
}
.alert-success-light hr {
  border-top-color: #aaeec6;
}
.alert-success-light .alert-link {
  color: #0f7138;
}
.alert-black {
  color: #000;
  background-color: #ccc;
  border-color: #b8b8b8;
}
.alert-black hr {
  border-top-color: #ababab;
}
.alert-black .alert-link {
  color: #000;
}
.alert-dark-light {
  color: #1d2023;
  background-color: #d4d4d5;
  border-color: #c2c3c4;
}
.alert-dark-light hr {
  border-top-color: #b5b6b7;
}
.alert-dark-light .alert-link {
  color: #060707;
}
.alert-stone {
  color: #1b1d1f;
  background-color: #d3d4d4;
  border-color: #c1c2c3;
}
.alert-stone hr {
  border-top-color: #b4b5b6;
}
.alert-stone .alert-link {
  color: #030404;
}
.alert-gray-color {
  color: #727272;
  background-color: #eaeaea;
  border-color: #e2e2e2;
}
.alert-gray-color hr {
  border-top-color: #d5d5d5;
}
.alert-gray-color .alert-link {
  color: #595959;
}
.alert-gray-dark {
  color: #41454a;
  background-color: #dddedf;
  border-color: #d0d1d3;
}
.alert-gray-dark hr {
  border-top-color: #c3c4c7;
}
.alert-gray-dark .alert-link {
  color: #292c2f;
}
.alert-smoke {
  color: #bababa;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-smoke hr {
  border-top-color: #efefef;
}
.alert-smoke .alert-link {
  color: #a1a1a1;
}
.alert-off-white {
  color: #a9a9a9;
  background-color: #f8f8f8;
  border-color: #f6f6f6;
}
.alert-off-white hr {
  border-top-color: #e9e9e9;
}
.alert-off-white .alert-link {
  color: #909090;
}
.alert-light-gray {
  color: #b5b5b5;
  background-color: #fcfcfc;
  border-color: #fafafa;
}
.alert-light-gray hr {
  border-top-color: #ededed;
}
.alert-light-gray .alert-link {
  color: #9c9c9c;
}
.alert-facebook {
  color: #4e6495;
  background-color: #e0e6f3;
  border-color: #d4ddee;
}
.alert-facebook hr {
  border-top-color: #c2cfe7;
}
.alert-facebook .alert-link {
  color: #3c4e74;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e9036e;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #e9036e;
  border-color: #e9036e;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #854710;
  background-color: #ffdec0;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #854710;
  background-color: #ffd1a7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #854710;
  border-color: #854710;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #325633;
  background-color: #d2e6d3;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #325633;
  background-color: #c2ddc3;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #325633;
  border-color: #325633;
}
.list-group-item-info {
  color: #0e5d79;
  background-color: #bfeaf9;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0e5d79;
  background-color: #a8e2f7;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e5d79;
  border-color: #0e5d79;
}
.list-group-item-warning {
  color: #846701;
  background-color: #ffefb8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #846701;
  background-color: #ffe99f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #846701;
  border-color: #846701;
}
.list-group-item-danger {
  color: #762f2f;
  background-color: #f7d1d1;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #762f2f;
  background-color: #f3bbbb;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #762f2f;
  border-color: #762f2f;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #121212;
  background-color: #c1c1c1;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #121212;
  background-color: #b4b4b4;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}
.list-group-item-success-light {
  color: #0f6c35;
  background-color: #bff2d4;
}
.list-group-item-success-light.list-group-item-action:hover,
.list-group-item-success-light.list-group-item-action:focus {
  color: #0f6c35;
  background-color: #aaeec6;
}
.list-group-item-success-light.list-group-item-action.active {
  color: #fff;
  background-color: #0f6c35;
  border-color: #0f6c35;
}
.list-group-item-black {
  color: #000;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus {
  color: #000;
  background-color: #ababab;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.list-group-item-dark-light {
  color: #141618;
  background-color: #c2c3c4;
}
.list-group-item-dark-light.list-group-item-action:hover,
.list-group-item-dark-light.list-group-item-action:focus {
  color: #141618;
  background-color: #b5b6b7;
}
.list-group-item-dark-light.list-group-item-action.active {
  color: #fff;
  background-color: #141618;
  border-color: #141618;
}
.list-group-item-stone {
  color: #121415;
  background-color: #c1c2c3;
}
.list-group-item-stone.list-group-item-action:hover,
.list-group-item-stone.list-group-item-action:focus {
  color: #121415;
  background-color: #b4b5b6;
}
.list-group-item-stone.list-group-item-action.active {
  color: #fff;
  background-color: #121415;
  border-color: #121415;
}
.list-group-item-gray-color {
  color: #4e4e4e;
  background-color: #e2e2e2;
}
.list-group-item-gray-color.list-group-item-action:hover,
.list-group-item-gray-color.list-group-item-action:focus {
  color: #4e4e4e;
  background-color: #d5d5d5;
}
.list-group-item-gray-color.list-group-item-action.active {
  color: #fff;
  background-color: #4e4e4e;
  border-color: #4e4e4e;
}
.list-group-item-gray-dark {
  color: #2d2f32;
  background-color: #d0d1d3;
}
.list-group-item-gray-dark.list-group-item-action:hover,
.list-group-item-gray-dark.list-group-item-action:focus {
  color: #2d2f32;
  background-color: #c3c4c7;
}
.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2d2f32;
  border-color: #2d2f32;
}
.list-group-item-smoke {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-smoke.list-group-item-action:hover,
.list-group-item-smoke.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-smoke.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}
.list-group-item-off-white {
  color: #737373;
  background-color: #f6f6f6;
}
.list-group-item-off-white.list-group-item-action:hover,
.list-group-item-off-white.list-group-item-action:focus {
  color: #737373;
  background-color: #e9e9e9;
}
.list-group-item-off-white.list-group-item-action.active {
  color: #fff;
  background-color: #737373;
  border-color: #737373;
}
.list-group-item-light-gray {
  color: #7c7c7c;
  background-color: #fafafa;
}
.list-group-item-light-gray.list-group-item-action:hover,
.list-group-item-light-gray.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed;
}
.list-group-item-light-gray.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.list-group-item-facebook {
  color: #354566;
  background-color: #d4ddee;
}
.list-group-item-facebook.list-group-item-action:hover,
.list-group-item-facebook.list-group-item-action:focus {
  color: #354566;
  background-color: #c2cfe7;
}
.list-group-item-facebook.list-group-item-action.active {
  color: #fff;
  background-color: #354566;
  border-color: #354566;
}
.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
  margin-top: 100px;
  margin-left: -20px;
  
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J001LjI1IDBsLTQgNCA0IDQgMS41LTEuNUw0LjI1IDRsMi41LTIuNUw1LjI1IDB6Jy8+PC9zdmc+);
}
.carousel-control-next-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J00yLjc1IDBsLTEuNSAxLjVMMy43NSA0bC0yLjUgMi41TDIuNzUgOGw0LTQtNC00eicvPjwvc3ZnPg==);
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  margin-bottom: 10px;
  list-style: none;
  opacity: 0.4;
  
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 0.5;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #e9036e !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ea6f00 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #60a662 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4c874d !important;
}
.bg-info {
  background-color: #1ab3e8 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1391bc !important;
}
.bg-warning {
  background-color: #fec701 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb9f01 !important;
}
.bg-danger {
  background-color: #e25a5a !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #da2f2f !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #222 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #090909 !important;
}
.bg-success-light {
  background-color: #1cd066 !important;
}
a.bg-success-light:hover,
a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #16a350 !important;
}
.bg-black {
  background-color: #000 !important;
}
a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #000 !important;
}
.bg-dark-light {
  background-color: #262a2e !important;
}
a.bg-dark-light:hover,
a.bg-dark-light:focus,
button.bg-dark-light:hover,
button.bg-dark-light:focus {
  background-color: #0f1112 !important;
}
.bg-stone {
  background-color: #232629 !important;
}
a.bg-stone:hover,
a.bg-stone:focus,
button.bg-stone:hover,
button.bg-stone:focus {
  background-color: #0c0d0d !important;
}
.bg-gray-color {
  background-color: #969696 !important;
}
a.bg-gray-color:hover,
a.bg-gray-color:focus,
button.bg-gray-color:hover,
button.bg-gray-color:focus {
  background-color: #7d7d7d !important;
}
.bg-gray-dark {
  background-color: #565b61 !important;
}
a.bg-gray-dark:hover,
a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #3e4246 !important;
}
.bg-smoke {
  background-color: #f5f5f5 !important;
}
a.bg-smoke:hover,
a.bg-smoke:focus,
button.bg-smoke:hover,
button.bg-smoke:focus {
  background-color: #dcdcdc !important;
}
.bg-off-white {
  background-color: #dedede !important;
}
a.bg-off-white:hover,
a.bg-off-white:focus,
button.bg-off-white:hover,
button.bg-off-white:focus {
  background-color: #c5c5c5 !important;
}
.bg-light-gray {
  background-color: #eee !important;
}
a.bg-light-gray:hover,
a.bg-light-gray:focus,
button.bg-light-gray:hover,
button.bg-light-gray:focus {
  background-color: #d5d5d5 !important;
}
.bg-facebook {
  background-color: #6684c4 !important;
}
a.bg-facebook:hover,
a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #4568b2 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #e9036e !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #60a662 !important;
}
.border-info {
  border-color: #1ab3e8 !important;
}
.border-warning {
  border-color: #fec701 !important;
}
.border-danger {
  border-color: #e25a5a !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #222 !important;
}
.border-success-light {
  border-color: #1cd066 !important;
}
.border-black {
  border-color: #000 !important;
}
.border-dark-light {
  border-color: #262a2e !important;
}
.border-stone {
  border-color: #232629 !important;
}
.border-gray-color {
  border-color: #969696 !important;
}
.border-gray-dark {
  border-color: #565b61 !important;
}
.border-smoke {
  border-color: #f5f5f5 !important;
}
.border-off-white {
  border-color: #dedede !important;
}
.border-light-gray {
  border-color: #eee !important;
}
.border-facebook {
  border-color: #6684c4 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.31rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.31rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.31rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.31rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.31rem !important;
}
.m-2 {
  margin: 0.63rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.63rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.63rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.63rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.63rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.25rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.25rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.25rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.25rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.25rem !important;
}
.m-5 {
  margin: 1.56rem !important;
}
.mt-5,
.my-5 {
  margin-top: 1.56rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 1.56rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 1.56rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 1.56rem !important;
}
.m-6 {
  margin: 1.88rem !important;
}
.mt-6,
.my-6 {
  margin-top: 1.88rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 1.88rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 1.88rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 1.88rem !important;
}
.m-7 {
  margin: 3.13rem !important;
}
.mt-7,
.my-7 {
  margin-top: 3.13rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 3.13rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 3.13rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 3.13rem !important;
}
.m-8 {
  margin: 3.75rem !important;
}
.mt-8,
.my-8 {
  margin-top: 3.75rem !important;
}
.mr-8,
.mx-8 {
  margin-right: 3.75rem !important;
}
.mb-8,
.my-8 {
  margin-bottom: 3.75rem !important;
}
.ml-8,
.mx-8 {
  margin-left: 3.75rem !important;
}
.m-9 {
  margin: 4.96rem !important;
}
.mt-9,
.my-9 {
  margin-top: 4.96rem !important;
}
.mr-9,
.mx-9 {
  margin-right: 4.96rem !important;
}
.mb-9,
.my-9 {
  margin-bottom: 4.96rem !important;
}
.ml-9,
.mx-9 {
  margin-left: 4.96rem !important;
}
.m-10 {
  margin: 6.25rem !important;
}
.mt-10,
.my-10 {
  margin-top: 6.25rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 6.25rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 6.25rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 6.25rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.31rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.31rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.31rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.31rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.31rem !important;
}
.p-2 {
  padding: 0.63rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.63rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.63rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.63rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.63rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.25rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.25rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.25rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.25rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.25rem !important;
}
.p-5 {
  padding: 1.56rem !important;
}
.pt-5,
.py-5 {
  padding-top: 1.56rem !important;
}
.pr-5,
.px-5 {
  padding-right: 1.56rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 1.56rem !important;
}
.pl-5,
.px-5 {
  padding-left: 1.56rem !important;
}
.p-6 {
  padding: 1.88rem !important;
}
.pt-6,
.py-6 {
  padding-top: 1.88rem !important;
}
.pr-6,
.px-6 {
  padding-right: 1.88rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 1.88rem !important;
}
.pl-6,
.px-6 {
  padding-left: 1.88rem !important;
}
.p-7 {
  padding: 3.13rem !important;
}
.pt-7,
.py-7 {
  padding-top: 3.13rem !important;
}
.pr-7,
.px-7 {
  padding-right: 3.13rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 3.13rem !important;
}
.pl-7,
.px-7 {
  padding-left: 3.13rem !important;
}
.p-8 {
  padding: 3.75rem !important;
}
.pt-8,
.py-8 {
  padding-top: 3.75rem !important;
}
.pr-8,
.px-8 {
  padding-right: 3.75rem !important;
}
.pb-8,
.py-8 {
  padding-bottom: 3.75rem !important;
}
.pl-8,
.px-8 {
  padding-left: 3.75rem !important;
}
.p-9 {
  padding: 4.96rem !important;
}
.pt-9,
.py-9 {
  padding-top: 4.96rem !important;
}
.pr-9,
.px-9 {
  padding-right: 4.96rem !important;
}
.pb-9,
.py-9 {
  padding-bottom: 4.96rem !important;
}
.pl-9,
.px-9 {
  padding-left: 4.96rem !important;
}
.p-10 {
  padding: 6.25rem !important;
}
.pt-10,
.py-10 {
  padding-top: 6.25rem !important;
}
.pr-10,
.px-10 {
  padding-right: 6.25rem !important;
}
.pb-10,
.py-10 {
  padding-bottom: 6.25rem !important;
}
.pl-10,
.px-10 {
  padding-left: 6.25rem !important;
}
.m-n1 {
  margin: -0.31rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.31rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.31rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.31rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.31rem !important;
}
.m-n2 {
  margin: -0.63rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.63rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.63rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.63rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.63rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.25rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.25rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.25rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.25rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.25rem !important;
}
.m-n5 {
  margin: -1.56rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -1.56rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -1.56rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -1.56rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -1.56rem !important;
}
.m-n6 {
  margin: -1.88rem !important;
}
.mt-n6,
.my-n6 {
  margin-top: -1.88rem !important;
}
.mr-n6,
.mx-n6 {
  margin-right: -1.88rem !important;
}
.mb-n6,
.my-n6 {
  margin-bottom: -1.88rem !important;
}
.ml-n6,
.mx-n6 {
  margin-left: -1.88rem !important;
}
.m-n7 {
  margin: -3.13rem !important;
}
.mt-n7,
.my-n7 {
  margin-top: -3.13rem !important;
}
.mr-n7,
.mx-n7 {
  margin-right: -3.13rem !important;
}
.mb-n7,
.my-n7 {
  margin-bottom: -3.13rem !important;
}
.ml-n7,
.mx-n7 {
  margin-left: -3.13rem !important;
}
.m-n8 {
  margin: -3.75rem !important;
}
.mt-n8,
.my-n8 {
  margin-top: -3.75rem !important;
}
.mr-n8,
.mx-n8 {
  margin-right: -3.75rem !important;
}
.mb-n8,
.my-n8 {
  margin-bottom: -3.75rem !important;
}
.ml-n8,
.mx-n8 {
  margin-left: -3.75rem !important;
}
.m-n9 {
  margin: -4.96rem !important;
}
.mt-n9,
.my-n9 {
  margin-top: -4.96rem !important;
}
.mr-n9,
.mx-n9 {
  margin-right: -4.96rem !important;
}
.mb-n9,
.my-n9 {
  margin-bottom: -4.96rem !important;
}
.ml-n9,
.mx-n9 {
  margin-left: -4.96rem !important;
}
.m-n10 {
  margin: -6.25rem !important;
}
.mt-n10,
.my-n10 {
  margin-top: -6.25rem !important;
}
.mr-n10,
.mx-n10 {
  margin-right: -6.25rem !important;
}
.mb-n10,
.my-n10 {
  margin-bottom: -6.25rem !important;
}
.ml-n10,
.mx-n10 {
  margin-left: -6.25rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.31rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.31rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.31rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.31rem !important;
  }
  .m-sm-2 {
    margin: 0.63rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.63rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.63rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.63rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.56rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.56rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.56rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.56rem !important;
  }
  .m-sm-6 {
    margin: 1.88rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.88rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.88rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.88rem !important;
  }
  .m-sm-7 {
    margin: 3.13rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.13rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.13rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.13rem !important;
  }
  .m-sm-8 {
    margin: 3.75rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.75rem !important;
  }
  .m-sm-9 {
    margin: 4.96rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.96rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.96rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.96rem !important;
  }
  .m-sm-10 {
    margin: 6.25rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.31rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.31rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.31rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.31rem !important;
  }
  .p-sm-2 {
    padding: 0.63rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.63rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.63rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.63rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.56rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.56rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.56rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.56rem !important;
  }
  .p-sm-6 {
    padding: 1.88rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.88rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.88rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.88rem !important;
  }
  .p-sm-7 {
    padding: 3.13rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.13rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.13rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.13rem !important;
  }
  .p-sm-8 {
    padding: 3.75rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.75rem !important;
  }
  .p-sm-9 {
    padding: 4.96rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.96rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.96rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.96rem !important;
  }
  .p-sm-10 {
    padding: 6.25rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6.25rem !important;
  }
  .m-sm-n1 {
    margin: -0.31rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.31rem !important;
  }
  .m-sm-n2 {
    margin: -0.63rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.63rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.56rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.56rem !important;
  }
  .m-sm-n6 {
    margin: -1.88rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.88rem !important;
  }
  .m-sm-n7 {
    margin: -3.13rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3.13rem !important;
  }
  .m-sm-n8 {
    margin: -3.75rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n9 {
    margin: -4.96rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4.96rem !important;
  }
  .m-sm-n10 {
    margin: -6.25rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.31rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.31rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.31rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.31rem !important;
  }
  .m-md-2 {
    margin: 0.63rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.63rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.63rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.63rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.56rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.56rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.56rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.56rem !important;
  }
  .m-md-6 {
    margin: 1.88rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.88rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.88rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.88rem !important;
  }
  .m-md-7 {
    margin: 3.13rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.13rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.13rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.13rem !important;
  }
  .m-md-8 {
    margin: 3.75rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.75rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.75rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.75rem !important;
  }
  .m-md-9 {
    margin: 4.96rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.96rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.96rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.96rem !important;
  }
  .m-md-10 {
    margin: 6.25rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6.25rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6.25rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.31rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.31rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.31rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.31rem !important;
  }
  .p-md-2 {
    padding: 0.63rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.63rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.63rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.63rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.56rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.56rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.56rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.56rem !important;
  }
  .p-md-6 {
    padding: 1.88rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.88rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.88rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.88rem !important;
  }
  .p-md-7 {
    padding: 3.13rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.13rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.13rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.13rem !important;
  }
  .p-md-8 {
    padding: 3.75rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.75rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.75rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.75rem !important;
  }
  .p-md-9 {
    padding: 4.96rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.96rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.96rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.96rem !important;
  }
  .p-md-10 {
    padding: 6.25rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6.25rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6.25rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6.25rem !important;
  }
  .m-md-n1 {
    margin: -0.31rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.31rem !important;
  }
  .m-md-n2 {
    margin: -0.63rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.63rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.56rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.56rem !important;
  }
  .m-md-n6 {
    margin: -1.88rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.88rem !important;
  }
  .m-md-n7 {
    margin: -3.13rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3.13rem !important;
  }
  .m-md-n8 {
    margin: -3.75rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.75rem !important;
  }
  .m-md-n9 {
    margin: -4.96rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4.96rem !important;
  }
  .m-md-n10 {
    margin: -6.25rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.31rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.31rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.31rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.31rem !important;
  }
  .m-lg-2 {
    margin: 0.63rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.63rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.63rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.63rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.56rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.56rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.56rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.56rem !important;
  }
  .m-lg-6 {
    margin: 1.88rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.88rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.88rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.88rem !important;
  }
  .m-lg-7 {
    margin: 3.13rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.13rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.13rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.13rem !important;
  }
  .m-lg-8 {
    margin: 3.75rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.75rem !important;
  }
  .m-lg-9 {
    margin: 4.96rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.96rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.96rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.96rem !important;
  }
  .m-lg-10 {
    margin: 6.25rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.31rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.31rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.31rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.31rem !important;
  }
  .p-lg-2 {
    padding: 0.63rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.63rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.63rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.63rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.56rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.56rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.56rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.56rem !important;
  }
  .p-lg-6 {
    padding: 1.88rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.88rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.88rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.88rem !important;
  }
  .p-lg-7 {
    padding: 3.13rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.13rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.13rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.13rem !important;
  }
  .p-lg-8 {
    padding: 3.75rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.75rem !important;
  }
  .p-lg-9 {
    padding: 4.96rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.96rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.96rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.96rem !important;
  }
  .p-lg-10 {
    padding: 6.25rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6.25rem !important;
  }
  .m-lg-n1 {
    margin: -0.31rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.31rem !important;
  }
  .m-lg-n2 {
    margin: -0.63rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.63rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.56rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.56rem !important;
  }
  .m-lg-n6 {
    margin: -1.88rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.88rem !important;
  }
  .m-lg-n7 {
    margin: -3.13rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3.13rem !important;
  }
  .m-lg-n8 {
    margin: -3.75rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n9 {
    margin: -4.96rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4.96rem !important;
  }
  .m-lg-n10 {
    margin: -6.25rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.31rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.31rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.31rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.31rem !important;
  }
  .m-xl-2 {
    margin: 0.63rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.63rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.63rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.63rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.56rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.56rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.56rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.56rem !important;
  }
  .m-xl-6 {
    margin: 1.88rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.88rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.88rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.88rem !important;
  }
  .m-xl-7 {
    margin: 3.13rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.13rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.13rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.13rem !important;
  }
  .m-xl-8 {
    margin: 3.75rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.75rem !important;
  }
  .m-xl-9 {
    margin: 4.96rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.96rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.96rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.96rem !important;
  }
  .m-xl-10 {
    margin: 6.25rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.31rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.31rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.31rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.31rem !important;
  }
  .p-xl-2 {
    padding: 0.63rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.63rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.63rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.63rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.56rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.56rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.56rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.56rem !important;
  }
  .p-xl-6 {
    padding: 1.88rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.88rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.88rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.88rem !important;
  }
  .p-xl-7 {
    padding: 3.13rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.13rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.13rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.13rem !important;
  }
  .p-xl-8 {
    padding: 3.75rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.75rem !important;
  }
  .p-xl-9 {
    padding: 4.96rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.96rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.96rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.96rem !important;
  }
  .p-xl-10 {
    padding: 6.25rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6.25rem !important;
  }
  .m-xl-n1 {
    margin: -0.31rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.31rem !important;
  }
  .m-xl-n2 {
    margin: -0.63rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.63rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.56rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.56rem !important;
  }
  .m-xl-n6 {
    margin: -1.88rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.88rem !important;
  }
  .m-xl-n7 {
    margin: -3.13rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3.13rem !important;
  }
  .m-xl-n8 {
    margin: -3.75rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n9 {
    margin: -4.96rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4.96rem !important;
  }
  .m-xl-n10 {
    margin: -6.25rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono,
    courier new, monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #e9036e !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #d16300 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #60a662 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #437744 !important;
}
.text-info {
  color: #1ab3e8 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #117fa5 !important;
}
.text-warning {
  color: #fec701 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #b28b01 !important;
}
.text-danger {
  color: #e25a5a !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #cc2424 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #222 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #000 !important;
}
.text-success-light {
  color: #1cd066 !important;
}
a.text-success-light:hover,
a.text-success-light:focus {
  color: #138d45 !important;
}
.text-black {
  color: #000 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #000 !important;
}
.text-dark-light {
  color: #262a2e !important;
}
a.text-dark-light:hover,
a.text-dark-light:focus {
  color: #030404 !important;
}
.text-stone {
  color: #232629 !important;
}
a.text-stone:hover,
a.text-stone:focus {
  color: #000 !important;
}
.text-gray-color {
  color: #969696 !important;
}
a.text-gray-color:hover,
a.text-gray-color:focus {
  color: #707070 !important;
}
.text-gray-dark {
  color: #565b61 !important;
}
a.text-gray-dark:hover,
a.text-gray-dark:focus {
  color: #323538 !important;
}
.text-smoke {
  color: #f5f5f5 !important;
}
a.text-smoke:hover,
a.text-smoke:focus {
  color: #cfcfcf !important;
}
.text-off-white {
  color: #dedede !important;
}
a.text-off-white:hover,
a.text-off-white:focus {
  color: #b8b8b8 !important;
}
.text-light-gray {
  color: #eee !important;
}
a.text-light-gray:hover,
a.text-light-gray:focus {
  color: #c8c8c8 !important;
}
.text-facebook {
  color: #6684c4 !important;
}
a.text-facebook:hover,
a.text-facebook:focus {
  color: #3e5da0 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::before,
  ::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .containerPublic {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
p {
  font-size: 0.875rem;
  color: #969696;
  font-family: montserrat, sans-serif;
  line-height: 21px;
  font-weight: 400;
}
a {
  transition: all 0.3s;
  display: inline-block;
}
a:hover {
  text-decoration: none;
}
.text-underline {
  text-decoration: underline !important;
}
.text-underline:hover {
  text-decoration: underline !important;
}
.text-hover-underline {
  text-decoration: none !important;
}
.text-hover-underline:hover {
  text-decoration: underline !important;
}
::-moz-selection {
  color: #fff;
  background-color: #e9036e;
}
::selection {
  color: #fff;
  background-color: #e9036e;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semibold {
  font-weight: 600 !important;
}
.font-size-base {
  font-size: 0.875rem !important;
}
.font-size-15 {
  font-size: 0.945rem !important;
}
.font-size-22 {
  font-size: 1.37375rem !important;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.letter-spacing-5 {
  letter-spacing: 5px;
}
.letter-spacing-10 {
  letter-spacing: 10px;
}
.letter-spacing-15 {
  letter-spacing: 15px;
}
.line-hight-16 {
  line-height: 1rem;
}
.line-hight-20 {
  line-height: 1.25rem;
}
.line-hight-21 {
  line-height: 1.3125rem;
}
.line-height-24 {
  line-height: 1.5rem !important;
}
.line-height-26 {
  line-height: 1.625rem !important;
}
.line-height-28 {
  line-height: 1.75rem !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.border-6 {
  border-width: 6px !important;
}
.border-7 {
  border-width: 7px !important;
}
.border-15 {
  border-width: 15px !important;
}
.border-top-5 {
  border-width: 5px !important;
}
.dashed {
  border: 2px dashed;
}
.border-gray {
  border-color: #dee2e6 !important;
}
.dashed-top {
  border-top: 2px dashed;
}
.dashed-bottom {
  border-bottom: 2px dashed;
}
.dashed-left {
  border-left: 2px dashed;
}
.dashed-right {
  border-right: 2px dashed;
}
.border-primary-light {
  border-color: rgba(255, 137, 30, 0.3) !important;
}
.rounded-top-0 {
  border-radius: 0 0 0.25rem 0.25rem !important;
}
.bg-parallax {
  background-attachment: fixed;
}
.bg-repeat {
  background-repeat: repeat;
}
.bg-img {
  background-size: cover !important;
  background-position: center !important;
  transition: all 0.3s linear;
  position: relative;
}
.bg-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bg-overlay-dark:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.bg-overlay-darken:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-overlay-warning:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 137, 30, 0.7);
}
.img-overlay {
  position: relative;
  overflow: hidden;
}
.img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}
.opacity-7 {
  opacity: 0.7;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-85 {
  opacity: 0.85;
}
.hover-bg-primary {
  transition: all 0.3s ease-in-out;
}
.hover-bg-primary:hover {
  background-color: #e9036e;
  border-color: #e9036e;
  color: #fff;
}
.hover-bg-secondary {
  transition: all 0.3s ease-in-out;
}
.hover-bg-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.hover-bg-success {
  transition: all 0.3s ease-in-out;
}
.hover-bg-success:hover {
  background-color: #60a662;
  border-color: #60a662;
  color: #fff;
}
.hover-bg-danger {
  transition: all 0.3s ease-in-out;
}
.hover-bg-danger:hover {
  background-color: #e25a5a;
  border-color: #e25a5a;
  color: #fff;
}
.hover-bg-warning {
  transition: all 0.3s ease-in-out;
}
.hover-bg-warning:hover {
  background-color: #fec701;
  border-color: #fec701;
  color: #fff;
}
.hover-bg-info {
  transition: all 0.3s ease-in-out;
}
.hover-bg-info:hover {
  background-color: #1ab3e8;
  border-color: #1ab3e8;
  color: #fff;
}
.hover-overlay {
  transition: all 0.3s ease-in-out;
}
.hover-overlay:hover {
  background-color: rgba(34, 34, 34, 0.5);
}
.hover-img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.hover-img-overlay-dark:hover {
  background-color: rgba(34, 34, 34, 0.3);
}
a.hover-text-primary:hover,
a.hover-text-primary:focus {
  color: #e9036e !important;
}
a.hover-text-success:hover,
a.hover-text-success:focus {
  color: #60a662 !important;
}
a.hover-text-danger:hover,
a.hover-text-danger:focus {
  color: #e25a5a !important;
}
a.hover-text-info:hover,
a.hover-text-info:focus {
  color: #1ab3e8 !important;
}
a.hover-text-warning:hover,
a.hover-text-warning:focus {
  color: #fec701 !important;
}
a.hover-text-success-light:hover,
a.hover-text-success-light:focus {
  color: #1cd066 !important;
}
a.hover-text-gray-color:hover,
a.hover-text-gray-color:focus {
  color: #969696 !important;
}
.hover-profile:hover .icon-setting {
  opacity: 1;
}
.header {
  position: relative;
}
.top-bar {
  position: relative;
  background-color: #f5f5f5;
  padding: 8px 0;
}
.top-bar a {
  color: #969696;
  font-size: 13px;
  font-family: sans-serif;
  transition: all 0.3s;
}
.top-bar .top-content {
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
  padding-top: 1.0625rem;
  padding-bottom: 1.125rem;
}
.search-item {
  position: relative;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: table;
  table-layout: fixed;
  z-index: 10000;
}
.search-item .search-box {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  width: 350px;
  padding: 15px;
  display: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  z-index: 10;
}
.search-item .search-box.visible {
  display: block !important;
}
.icon-toggle {
  display: table-cell;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}
.icon-toggle.active > i {
  opacity: 0;
  transform: translateY(-20px) scale(0.7);
}
.icon-toggle.active::after {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.icon-toggle > i {
  display: inline-block;
  font-style: normal;
  opacity: 1;
  position: relative;
  transform: translateY(0) scale(1);
}
.icon-toggle:after {
  content: "\f00d";
  display: block;
  font-family: fontawesome;
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(20px) scale(0.7);
}
.dropdown-sm {
  position: relative;
}
.dropdown-sm .btn {
  padding: 0;
  font-family: sans-serif;
  color: #969696;
}
.dropdown-sm .btn:hover,
.dropdown-sm .btn:focus {
  text-decoration: none;
}
.dropdown-sm .dropdown-menu {
  min-width: 6rem;
  padding: 0.3125rem;
  top: 6px !important;
  z-index: 9999;
}
.dropdown-sm .dropdown-menu .dropdown-item {
  font-size: 0.75rem;
  padding: 6px 12px;
  text-transform: capitalize;
  color: #969696;
  transition: all 0.3s ease-in-out;
}
.dropdown-sm .dropdown-menu .dropdown-item:hover {
  background-color: #e9036e;
  color: #fff;
}
.navbar {
  padding: 11px 0;
  z-index: 100;
  transition: 0.4s ease-in-out;
  position: relative;
}
@media (min-width: 768px) {
  .navbar {
    padding: 0;
  }
}
.navbar > .containerPublic {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.navbar .navbar-brand {
  margin-left: 15px;
  margin-right: 0;
  padding: 16px;
}
@media (min-width: 768px) {
  .navbar .navbar-brand {
    padding: 0;
  }
}
.navbar .navbar-brand img {
  width: 75%;
}
@media (min-width: 768px) {
  .navbar .navbar-brand img {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-brand img {
    width: 100%;
  }
}
.navbar .navbar-toggler {
  margin-right: 20px;
  background-color: #fff;
  border-color: #ddd;
  padding: 0.4375rem 0.75rem;
  color: #fff;
  outline: 0;
}
.navbar .navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #888;
  margin: 4px 0;
}
.navbar .navbar-collapse {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  max-height: 400px;
  margin-top: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
}
@media (min-width: 768px) {
  .navbar .navbar-collapse {
    position: static;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
.navbar .navbar-collapse.show {
  overflow-y: auto;
}
@media (min-width: 768px) {
  .navbar .navbar-collapse.show {
    overflow-y: hidden;
  }
}
.dropdown-menu {
  border-radius: 0;
}
.navbar-nav {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.navbar-nav .dropdown > a {
  font-size: 0.875rem;
}
.navbar-nav .dropdown .nav-link {
  line-height: normal;
  font-size: 13px;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  margin-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-nav .dropdown .nav-link i {
  margin-right: 7px;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .nav-link {
    display: block;
    width: auto;
    margin-left: 0;
    padding: 28px 7px 10px;
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .nav-link {
    padding: 30px 15px 17px;
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .navbar-nav .dropdown .nav-link {
    padding: 30px 15px 17px;
  }
}
.navbar-nav .dropdown .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 200;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 0;
    width: 210px;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 21px;
    padding-left: 15px;
    margin-top: -1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu {
    width: 230px;
  }
}
.navbar-nav .dropdown .dropdown-menu li {
  position: relative;
}
.navbar-nav .dropdown .dropdown-menu ul li a {
  display: inline-block;
  color: #969696;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: 400;
  transition: all, 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu ul li a {
    padding: 10px 12px;
    display: inline-block;
    text-transform: capitalize;
    font-size: 0.75rem;
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu ul li a {
    font-size: 0.875rem;
  }
}
.navbar-nav .dropdown .dropdown-menu .dropdown-item {
  color: #969696;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: 400;
  padding-left: 50px;
  transition: all, 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    padding: 10px 12px;
    display: inline-block;
    text-transform: capitalize;
    font-size: 0.75rem;
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    font-size: 0.875rem;
  }
}
.navbar-nav .dropdown .dropdown-menu .sub-menu {
  list-style: none;
  padding-left: 20px;
  margin-left: 50px;
  position: relative;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #fff;
    width: 185px;
    visibility: hidden;
    opacity: 0;
    margin-left: 3px;
    padding: 5px;
    border-radius: 4px;
    border-left: 4px solid;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    width: 190px;
  }
}
@media (min-width: 1200px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    width: 210px;
  }
}
.navbar-nav .dropdown .dropdown-menu .sub-menu:before {
  position: absolute;
  left: 0;
  height: 90%;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  background-color: #dee2e6;
  content: "";
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 7px solid #000;
    top: 20px;
    margin-top: -7px;
    content: "";
    display: inline-block;
    left: -10px;
    position: absolute;
  }
}
.navbar-nav .dropdown .dropdown-menu li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-nav .dropdown .dropdown-menu i {
  display: none;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu i {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.navbar-nav .dropdown .dropdown-menu.show .nav-link.dropdown-toggle:after {
  content: "\f106";
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu.show .nav-link.dropdown-toggle:after {
    content: "";
  }
}
.navbar-nav .dropdown .dropdown-toggle:after {
  display: inline-block;
  width: auto;
  height: auto;
  font-family: fontawesome;
  margin-left: 5px;
  vertical-align: middle;
  content: "\f107";
  border: 0;
  color: inherit;
  position: absolute;
  top: 20px;
  right: 15px;
  transform: translateY(-50%);
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-toggle:after {
    content: "";
  }
}
.navbar-nav .dropdown.show .dropdown-toggle:after {
  content: "\f106";
}
@media (min-width: 768px) {
  .navbar-nav .dropdown.show .dropdown-toggle:after {
    content: "";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 23px;
    padding-bottom: 23px;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .mega-dropdown {
    position: static;
  }
}
.nav-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .nav-icon {
    margin: 0 auto 10px;
    margin-bottom: 10px;
    display: block;
  }
}
.dropdown-menu.row.show {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .dropdown-menu.row.show {
    width: 100%;
    padding-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
  }
}
.dropdown-menu.row.show .list-unstyled {
  position: relative;
}
.dropdown-menu.row.show .list-unstyled li {
  padding-left: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0.8125rem;
}
@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled li {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.94rem;
    padding-left: 0;
  }
}
.dropdown-menu.row.show .list-unstyled li a {
  color: #969696;
}
.dropdown-menu.row.show .list-unstyled li:first-child {
  font-weight: 400;
  padding-left: 25px;
  font-size: 0.875rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled li:first-child {
    margin-top: 13px;
    margin-bottom: 13px;
    padding-bottom: 12px;
    padding-left: 0;
    margin-left: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 992px) {
  .dropdown-menu.row.show .list-unstyled li:first-child {
    font-size: 0.875rem;
  }
}
.dropdown-menu.row.show .list-unstyled:before {
  position: absolute;
  left: 0;
  height: 60%;
  top: 50%;
  transform: translateY(-28%);
  width: 3px;
  background-color: transparent;
  content: "";
}
@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled:before {
    display: none;
  }
}
.nav-item.dropdown {
  background-color: transparent !important;
}
.nav-item.dropdown .nav-link {
  color: #222;
  font-weight: 400;
}
@media (min-width: 768px) {
  .nav-item.dropdown .nav-link {
    color: #fff;
  }
}
.nav-item.dropdown .nav-link.active {
  color: #e9036e !important;
}
@media (min-width: 768px) {
  .nav-item.dropdown .nav-link.active {
    color: #fff !important;
  }
}
.nav-item.dropdown .nav-link:hover {
  color: #e9036e !important;
}
@media (min-width: 768px) {
  .nav-item.dropdown .nav-link:hover {
    color: #fff !important;
  }
}
.nav-item.dropdown .dropdown-menu {
  border-radius: 5px;
}
.nav-item.dropdown .dropdown-menu li a:hover {
  background-color: transparent;
  color: #e9036e;
  border-radius: 10px;
}
.nav-item.dropdown .dropdown-menu li a.active {
  background-color: transparent;
  color: #222 !important;
  border-radius: 10px;
}
@media (min-width: 768px) {
  .nav-item.dropdown .dropdown-menu li a.active {
    color: #e9036e !important;
  }
}
.nav-item.dropdown .dropdown-menu li .dropdown-item.active {
  background-color: transparent;
  color: #e9036e;
}
.nav-item.dropdown .dropdown-menu li .dropdown-item.active:hover {
  background-color: transparent;
  color: #fff;
}
.nav-item.dropdown .dropdown-menu li .sub-menu {
  border-left-color: #e9036e;
}
.nav-item.dropdown .dropdown-menu li .sub-menu:after {
  border-right-color: #e9036e;
}
.nav-item.dropdown .dropdown-menu li:first-child {
  color: #222;
}
@media (min-width: 768px) {
  .nav-item.dropdown .dropdown-menu li a:hover {
    background-color: transparent;
    color: #e9036e !important;
  }
  .nav-item.dropdown .dropdown-menu li a.active {
    color: #e9036e;
  }
}
.nav-item.dropdown .dropdown-toggle.active:after {
  color: #e9036e;
}
@media (min-width: 768px) {
  .navbar-nav-right {
    padding-right: 30px;
  }
}
.navbar-nav-right .nav-item-left.dropdown {
  margin-right: 10px;
}
.navbar-nav-right .nav-item-left.dropdown .nav-link .icon {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-nav-right .nav-item-left.dropdown .nav-link .badge-pill {
  position: absolute;
  top: -6px;
  right: 3px;
  border-radius: 3px;
  font-size: 11px;
  padding: 0 5px;
}
.navbar-nav-right .nav-item-left.dropdown .dropdown-left {
  top: 100%;
  min-width: 228px;
  margin: 0;
  margin-top: 12px;
  padding: 0;
}
@media (min-width: 768px) {
  .navbar-nav-right .nav-item-left.dropdown .dropdown-left {
    min-width: 320px;
  }
}
.navbar-nav-right .nav-item-left.dropdown .dropdown-left h5 {
  margin: 0;
}
.navbar-nav-right .nav-item-left.dropdown .dropdown-left::before {
  position: absolute;
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 3px 0 0 0;
  transform: rotate(45deg);
  top: -6px;
  right: 16px;
}
.navbar-nav-right
  .nav-item-left.dropdown
  .dropdown-left
  .list-group
  .list-group-item {
  border: 0;
  padding: 0.92rem 1.25rem;
}
@media (min-width: 992px) {
  .navbar-nav-right
    .nav-item-left.dropdown
    .dropdown-left
    .list-group
    .list-group-item {
    padding: 0.78rem 1.25rem;
  }
}
.navbar-nav-right
  .nav-item-left.dropdown
  .dropdown-left
  .list-group
  .list-group-item:hover {
  background-color: #fafafa;
}
.navbar-nav-right
  .nav-item-left.dropdown
  .dropdown-left
  .list-group
  .list-group-item.first-child {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}
.navbar-nav-right
  .nav-item-left.dropdown
  .dropdown-left
  .list-group
  .list-group-item.last-child {
  border-radius: 0 0 0.25rem 0.25rem !important;
}
.navbar-nav-right .nav-item-left {
  position: relative;
  right: 0;
}
.navbar-nav-right .nav-item-left .dropdown-toggle {
  position: relative;
}
.navbar-nav-right .nav-item-left .dropdown-toggle::after {
  content: "\f107";
  font-family: fontawesome;
  border-top: 0;
  color: rgba(255, 255, 255, 0.4);
  border: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.nav-components {
  flex-direction: column;
}
.nav-components .nav-item .nav-link {
  color: #969696;
  border-radius: 0.8rem;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
}
.nav-components .nav-item .nav-link:hover {
  color: #e9036e;
}
.nav-components .nav-item .nav-link.active {
  color: #e9036e;
}
.navbar-btn {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 15px;
}
.navbar-btn .btn {
  padding: 9px 19px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.3s;
}
.navbar-btn .btn:hover {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .navbar-btn .btn {
    font-size: 10px;
  }
}
@media (min-width: 992px) {
  .navbar-btn .btn {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .navbar-btn {
    padding-top: 28px;
    margin-left: 7px;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-btn {
    padding-top: 30px;
  }
}
.navbar-dark-light {
  background-color: #262a2e;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .navbar-dark-light {
    box-shadow: none;
  }
}
@media (min-width: 768px) {
  .navbar-dark-light .nav-item.dropdown .nav-link {
    color: rgba(255, 255, 255, 0.4);
  }
}
.video-tab {
  position: relative;
  top: -50px;
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .video-tab {
    width: 70%;
    left: 50%;
    top: -129px;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .video-tab {
    width: 100%;
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
  }
}
.video-tab .content .tab-heading h2 {
  color: #fff;
  font-size: 19px;
}
@media (min-width: 992px) {
  .video-tab .content .tab-heading h2 span {
    display: block;
    font-size: 25px;
  }
}
@media (min-width: 768px) {
  .video-tab .content .tab-heading h2 {
    font-size: 25px;
  }
}
@media (min-width: 992px) {
  .video-tab .content .tab-heading h2 {
    font-size: 32px;
  }
}
.video-tab .content .tab-features {
  background-color: #fff;
  border-radius: 5px;
}
.video-tab .content .tab-features .nav-tabs {
  border-bottom: 0;
}
.video-tab .content .tab-features .nav-tabs .nav-item {
  width: 25%;
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link {
  padding: 13px 0;
  border: 1px solid #dedede;
  border-top-width: 2px;
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.first-child {
  border-left-color: transparent;
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.last-child {
  border-right-color: transparent;
}
@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link {
    padding: 20px 0;
  }
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
  font-size: 20px;
  color: #969696;
}
@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
    font-size: 25px;
  }
}
@media (min-width: 992px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
    font-size: 30px;
  }
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link span {
  font-size: 12px;
  color: #969696;
}
@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link span {
    font-size: 14px;
  }
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active {
  border-top-color: #e9036e;
  border-bottom-color: transparent;
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active i {
  color: #e9036e;
}
.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active span {
  color: #222;
}
.destination-tabs {
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: -webkit-fill-available;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .destination-tabs {
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .destination-tabs .nav-item {
    margin: 0;
  }
}
.destination-tabs .nav-item .nav-link {
  color: #222;
  text-transform: uppercase;
  border: transparent;
  display: inline-block;
  position: relative;
  background-color: transparent;
  z-index: 1;
}
.destination-tabs .nav-item .nav-link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  transition: all 300ms ease-out;
  border-bottom: 3px solid #e9036e;
  opacity: 0;
}
.destination-tabs .nav-item .nav-link.active,
.destination-tabs .nav-item .nav-link:hover {
  cursor: pointer;
}
.destination-tabs .nav-item .nav-link.active:before,
.destination-tabs .nav-item .nav-link:hover:before {
  width: 100%;
  opacity: 1;
}
.destination-tabs .nav-item .nav-link.active:hover {
  cursor: default;
}
@media (min-width: 768px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 15px;
  }
}
@media (min-width: 992px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 13px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 15px;
  }
}
.destination-tabs .nav-item:last-child {
  padding: 10px 15px;
}
.blog-tabs {
  border-bottom: transparent;
  flex-direction: column;
}
@media (min-width: 768px) {
  .blog-tabs {
    flex-direction: row;
  }
}
.blog-tabs .nav-item {
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  .blog-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 4px;
  }
  .blog-tabs .nav-item:last-child {
    margin-right: 0;
  }
}
.blog-tabs .nav-item .nav-link {
  color: #222;
  border-color: #eee;
  border-radius: 4px;
}
@media (min-width: 768px) {
  .blog-tabs .nav-item .nav-link {
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
.blog-tabs .nav-item .nav-link.active,
.blog-tabs .nav-item .nav-link:hover {
  border-color: #eee #eee #f5f5f5;
  background-color: #f5f5f5;
}
.blog-tabs .nav-item .nav-link.active {
  cursor: default;
}
.btn-primary {
  color: #fff;
}
.btn-primary.btn-sale:hover {
  background-color: transparent !important;
  border-color: #fff;
}
.btn-outline-primary:hover {
  color: #fff;
}
.btn-outline-primary.btn-sale {
  border-color: #fff;
  color: #fff;
}
.btn-outline-primary.btn-sale:hover {
  border-color: #e9036e;
}
.btn-append {
  background-color: #565b61;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
}
.btn-hover.btn-outline-secondary,
.btn-xs.btn-outline-secondary,
.btn-sm.btn-outline-secondary,
.btn-group-sm > .btn-outline-secondary.btn {
  border-color: #ddd;
  color: #969696;
}
.btn-hover.btn-outline-secondary:hover,
.btn-xs.btn-outline-secondary:hover,
.btn-sm.btn-outline-secondary:hover,
.btn-group-sm > .btn-outline-secondary.btn:hover {
  border-color: var(--colorE);
  background-color: var(--colorE);
  color: #fff;
}
.btn-hover.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-hover.btn-outline-secondary
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-hover.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-xs.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-xs.btn-outline-secondary
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-xs.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-sm.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-group-sm
  > .btn-outline-secondary.btn-outline-secondary.btn:not(:disabled):not(.disabled):active,
.btn-sm.btn-outline-secondary
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-group-sm
  > .btn-outline-secondary.btn
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-sm.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-group-sm
  > .btn-outline-secondary.btn
  .show
  > .btn-outline-secondary.dropdown-toggle {
  border-color: #e9036e;
  background-color: #e9036e;
}
.btn-xs {
  font-size: 0.8125rem;
  padding: 0.56rem 0.6rem;
  line-height: 1rem;
}
.btn-white {
  background-color: #fff;
  color: #e9036e !important;
}
.btn-outline-white {
  border-color: #fff;
  color: #969696;
}
.btn-outline-white:hover {
  border-color: #e9036e;
  background-color: #e9036e;
  color: #fff;
}
.btn-booking {
  font-size: 15px;
}
.btn-booking.btn-white {
  border-color: #fff;
  background-color: #fff;
  color: #e9036e;
}
.btn-booking.btn-white:hover {
  color: #e9036e;
}
.btn-booking.btn-outline-white {
  background-color: transparent;
  color: #fff;
}
.btn-booking.btn-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #e9036e;
}
.btn-payment:hover {
  background-color: #e9036e !important;
  border-color: #e9036e !important;
  color: #fff !important;
}
.button-group {
  text-align: center;
  margin-bottom: 30px;
}
.button-group .button {
  padding: 0 1.56rem;
  height: 39px;
  font-size: 0.875rem;
  color: #222;
  text-transform: uppercase;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #ddd;
  color: #969696;
  border-radius: 0.25rem;
  margin: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.button-group .button:hover {
  background-color: #e9036e;
  border-color: #e9036e;
  color: #fff;
}
.button-group .button:focus {
  outline: 0;
}
.button-group .button.is-checked {
  background-color: #e9036e;
  border-color: #e9036e;
  color: #fff;
  transition: all 0.3s;
}
#rev_slider_12_1 .metis.tparrows {
  background: rgba(255, 255, 255, 0.15);
  padding: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 992px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 27px;
    width: 85px;
    height: 85px;
  }
}
@media (min-width: 1200px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 25px;
  }
}
#rev_slider_12_1 .rev-btn {
  background-color: #e9036e !important;
}
#rev_slider_12_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2);
}
#rev_slider_12_1 .metis.tparrows:before {
  color: #fff;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
#rev_slider_12_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}
.hesperiden.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: 0 0;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
  border-radius: 8px;
}
.hesperiden .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.01) 0,
    rgba(255, 255, 255, 0.01) 100%
  );
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
}
.hesperiden .tp-bullet:hover,
.hesperiden .tp-bullet.selected {
  background: rgba(225, 225, 225, 0.7);
}
#rev_slider_18_1 .metis.tparrows {
  background: rgba(255, 255, 255, 0.15);
  padding: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 992px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 27px;
    width: 85px;
    height: 85px;
  }
}
@media (min-width: 1200px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 25px;
  }
}
#rev_slider_18_1 .rev-btn:hover {
  background-color: #e9036e !important;
  border-color: #e9036e !important;
}
#rev_slider_18_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2);
}
#rev_slider_18_1 .metis.tparrows:before {
  color: #fff;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
#rev_slider_18_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}
#rev_slider_wrapper1 #rev_slider_containerPublic .slide-layer-4 {
  text-transform: uppercase;
  background-color: #e9036e;
  border: 0;
}
#rev_slider_wrapper1 #rev_slider_containerPublic .slide-layer-4 a {
  color: #fff;
}
#rev_slider_wrapper1 #rev_slider_containerPublic .slide-layer-4:hover {
  background: #e9036e;
  cursor: pointer;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-1 {
  font-weight: 700;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  z-index: 10;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  z-index: 10;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-3 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-3:hover {
  background: #e9036e;
  border: 2px solid #e9036e;
  cursor: pointer;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-3:focus {
  text-decoration: none;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-4 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-4:hover {
  background: #e9036e;
  border: 2px solid #e9036e;
  cursor: pointer;
}
#rev_slider_wrapper2 #rev_slider_containerPublic .slide-layer-4:focus {
  text-decoration: none;
}
#rev_slider_wrapper3 #rev_slider_containerPublic .slide-layer-2 {
  text-transform: uppercase;
}
#rev_slider_wrapper3
  #rev_slider_containerPublic
  .slide-layer-3
  .containerPublic-containerPublic-custom {
  width: 530px !important;
  margin-left: -15px;
  margin-right: -15px;
}
#rev_slider_wrapper3 #rev_slider_containerPublic .tp-rightarrow {
  display: none;
}
#rev_slider_wrapper3 #rev_slider_containerPublic .tp-leftarrow {
  display: none;
}
#rev_slider_wrapper3 #rev_slider_containerPublic .tp-bullet {
  display: none;
}
.bannercontainerPublic .rev_slider_wrapper .fullscreenbanner {
  position: relative;
  font-family: montserrat, sans-serif;
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-dottedoverlay.twoxtwo {
  background: rgba(0, 0, 0, 0.15);
  background-image: none !important;
  z-index: 1;
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner:hover
  .tp-rightarrow.tparrows,
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner:hover
  .tp-leftarrow.tparrows {
  display: block;
}
.bannercontainerPublic .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows {
  left: 101.5% !important;
  display: none;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-rightarrow.tparrows {
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 992px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-rightarrow.tparrows {
    width: 85px;
    height: 85px;
  }
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-rightarrow.tparrows:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-rightarrow.tparrows:before {
  content: "\e825";
}
@media (min-width: 768px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-rightarrow.tparrows:before {
    line-height: 60px;
  }
}
@media (min-width: 992px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-rightarrow.tparrows:before {
    line-height: 85px;
  }
}
.bannercontainerPublic .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows {
  display: none;
  left: -20px !important;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-leftarrow.tparrows {
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 992px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-leftarrow.tparrows {
    width: 85px;
    height: 85px;
  }
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-leftarrow.tparrows:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-leftarrow.tparrows:before {
  content: "\e824";
}
@media (min-width: 768px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-leftarrow.tparrows:before {
    line-height: 60px;
  }
}
@media (min-width: 992px) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-leftarrow.tparrows:before {
    line-height: 85px;
  }
}
.bannercontainerPublic .rev_slider_wrapper .fullscreenbanner .tp-bullets {
  top: 97% !important;
  opacity: 1 !important;
  height: auto !important;
}
@media (min-width: 992px) {
  .bannercontainerPublic .rev_slider_wrapper .fullscreenbanner .tp-bullets {
    bottom: 96px !important;
  }
}
.bannercontainerPublic .rev_slider_wrapper .fullscreenbanner .tp-bullets .tp-bullet {
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 50%;
  box-shadow: none !important;
  width: 6px !important;
  height: 6px !important;
  border: 2px solid transparent !important;
  display: inline-block;
  margin-bottom: 0 !important;
  transition: background-color 0.2s, border-color 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .bannercontainerPublic
    .rev_slider_wrapper
    .fullscreenbanner
    .tp-bullets
    .tp-bullet {
    transition: none;
  }
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-bullets
  .tp-bullet.selected {
  box-shadow: none !important;
  background: 0 0 !important;
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #fff !important;
  margin-right: 0 !important;
  margin-left: -4px !important;
  margin-bottom: -3px !important;
  margin-top: -3px;
}
.bannercontainerPublic
  .rev_slider_wrapper
  .fullscreenbanner
  .tp-bullets
  .tp-bullet.hover {
  box-shadow: none !important;
  background: 0 0 !important;
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #fff !important;
  margin-bottom: -3px !important;
}
.boxed .forcefullwidth_wrapper_tp_banner {
  overflow: hidden !important;
}
.default {
  background-image: url(../img/patterns/pattern1.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
.pattern-01 {
  background-image: url(../img/patterns/pattern2.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
.pattern-02 {
  background-image: url(../img/patterns/pattern3.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
.pattern-03 {
  background-image: url(../img/patterns/pattern4.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
.pattern-04 {
  background-image: url(../img/patterns/pattern5.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
body.boxed {
  margin: 0 auto;
  position: relative;
}
@media (min-width: 768px) {
  body.boxed {
    max-width: 700px;
  }
  body.boxed .navbar-sticky {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  body.boxed {
    max-width: 950px;
  }
  body.boxed .navbar-sticky {
    max-width: 950px;
  }
}
@media (min-width: 1200px) {
  body.boxed {
    max-width: 1250px;
  }
  body.boxed .navbar-sticky {
    max-width: 1250px;
  }
}
body.boxed .main-wrapper {
  background: #fff;
}
img.fancybox-image {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
}
.media {
  position: relative;
  transition: all 0.3s;
}
.media-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.media-object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.media-hoverable {
  overflow: hidden;
  border-radius: 0.25rem;
}
@media (min-width: 992px) {
  .media-hoverable:hover .media-img {
    transform: scale(1.2) rotate(0);
  }
  .media-hoverable:hover .media-object h3 {
    color: #e9036e;
  }
  .media-hoverable:hover .media-img-overlay {
    visibility: visible;
    opacity: 1;
  }
  .media-hoverable:hover .media-img-overlay .overlay-content {
    top: 50%;
  }
}
.media-hoverable .media-content {
  position: relative;
  width: 100%;
}
.media-hoverable .media-img {
  transition: transform 0.8s ease-in-out;
  width: 100%;
  transform: scale(1.2) rotate(0);
}
@media (min-width: 992px) {
  .media-hoverable .media-img {
    transform: scale(1.01) rotate(0);
  }
}
.media-hoverable .media-object {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.media-hoverable .media-object h3 {
  color: #e9036e;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .media-hoverable .media-object h3 {
    color: #fff;
  }
}
.media-hoverable .media-img-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .media-hoverable .media-img-overlay {
    visibility: hidden;
    opacity: 0;
  }
}
.media-hoverable .media-img-overlay .overlay-content {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .media-hoverable .media-img-overlay .overlay-content {
    top: 57%;
  }
}
.media-hoverable .media-img-overlay .overlay-content h3 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 55px;
}
.media-hover-overlay {
  overflow: hidden;
  border-radius: 0.25rem;
}
@media (min-width: 992px) {
  .media-hover-overlay:hover .media-img-overlay {
    visibility: visible;
    opacity: 1;
  }
  .media-hover-overlay:hover .media-img-overlay .overlay-content {
    bottom: 0;
  }
  .media-hover-overlay:hover .media-object .content {
    top: 35%;
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .media-hover-overlay:hover .media-object .content {
    top: 40%;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .media-hover-overlay:hover .media-object .content {
    top: 25%;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .media-hover-overlay:hover .media-object .content {
    top: 45%;
  }
}
.media-hover-overlay .media-content {
  position: relative;
  width: 100%;
}
.media-hover-overlay .media-img {
  transition: transform 0.8s ease-in-out;
  width: 100%;
  transform: scale(1.01) rotate(0);
}
.media-hover-overlay .media-object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.media-hover-overlay .media-object .content {
  position: absolute;
  left: 30px;
  top: 35%;
  transition: all 0.3s ease-in-out;
}
.media-hover-overlay .media-object .content h3,
.media-hover-overlay .media-object .content h6 {
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.media-hover-overlay .media-object .content h6 {
  font-weight: 400;
}
@media (min-width: 768px) {
  .media-hover-overlay .media-object .content {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .media-hover-overlay .media-object .content {
    top: 60%;
  }
}
@media (min-width: 1200px) {
  .media-hover-overlay .media-object .content {
    top: 68%;
  }
}
.media-hover-overlay .media-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .media-hover-overlay .media-img-overlay {
    visibility: hidden;
    opacity: 0;
  }
}
.media-hover-overlay .media-img-overlay .overlay-content {
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  left: 30px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .media-hover-overlay .media-img-overlay .overlay-content {
    bottom: -65px;
  }
}
.media-overlay {
  overflow: hidden;
  border-radius: 0.25rem;
}
.media-overlay .media-content {
  position: relative;
  width: 100%;
}
.media-overlay .media-img {
  transition: transform 0.8s ease-in-out;
  width: 100%;
  transform: scale(1.01) rotate(0);
}
.media-overlay .media-object {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.media-overlay .media-object h3 {
  color: #fff;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.media-overlay .media-img-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.media-overlay .media-img-overlay .overlay-content {
  text-align: center;
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.media-overlay .media-img-overlay .overlay-content h3 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 55px;
}
.media-overlay:hover .media-img {
  transform: scale(1.2) rotate(0);
}
.media-overlay:hover .media-img-overlay {
  visibility: visible;
  opacity: 1;
}
.media-overlay:hover .media-object h3 {
  color: #e9036e;
}
.media-overlay:hover .overlay-content {
  top: 50%;
}
.media-top {
  margin-top: 0;
}
@media (min-width: 768px) {
  .media-top {
    margin-top: -50px;
  }
}
@media (min-width: 992px) {
  .media-top {
    margin-top: 0;
  }
}
.media-hover .media-img {
  transition: all 0.3s;
  width: 100%;
}
.media-hover:hover .media-img {
  opacity: 0.8;
}
.media-large {
  border-radius: 5px;
}
.media-large img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .media-large img {
    width: auto;
    border-radius: 5px 0 0 5px;
  }
}
@media (min-width: 992px) {
  .media-large img {
    width: 100%;
  }
}
.media-large img:hover {
  opacity: 0.8;
}
.media-large .media-body:first-child {
  padding: 20px;
}
@media (min-width: 992px) {
  .media-large .media-body:first-child {
    padding: 40px;
  }
}
@media (min-width: 1200px) {
  .media-large .media-body:first-child {
    padding: 50px;
  }
}
.media-large .media-body .list-unstyled li {
  font-size: 14px;
  color: #fff;
}
.media-large .media-body .list-unstyled li:first-child {
  text-transform: uppercase;
}
.media-large .media-body .list-unstyled li:first-child i {
  color: #e9036e;
}
.media-large .media-body .list-unstyled li a {
  color: #969696;
  font-size: 14px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .media-large .media-body .list-unstyled li a {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .media-large .media-body .list-unstyled li a {
    font-size: 14px;
  }
}
.media-large .media-body .list-unstyled li a i {
  font-size: 8px;
  transform: rotate(45deg);
}
.media-large .media-body .list-unstyled li a:hover {
  color: #e9036e;
}
.media-img-wrapper .media-img {
  width: 100%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .media-img-wrapper .media-img {
    width: auto;
  }
}
@media (min-width: 992px) {
  .media-img-wrapper .media-img {
    width: 172px;
  }
}
@media (min-width: 1200px) {
  .media-img-wrapper .media-img {
    width: 215px;
  }
}
.media-img-wrapper .media-img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.4s;
}
@media (min-width: 992px) {
  .media-img-wrapper .media-img img {
    width: auto;
  }
}
.media-img-wrapper .media-body {
  padding: 1.5rem 0;
}
@media (min-width: 768px) {
  .media-img-wrapper .media-body {
    padding: 1.5rem 2rem;
  }
}
@media (min-width: 992px) {
  .media-img-wrapper .media-body {
    padding: 1.5rem 1rem 1.5rem 2rem;
  }
}
@media (min-width: 1200px) {
  .media-img-wrapper .media-body {
    padding: 1.6rem;
  }
}
.media-img-wrapper .media-body h6 {
  line-height: 26px;
}
.media-img-wrapper .media-body h6 a {
  color: #222;
}
.media-img-wrapper .media-body .list-unstyled a {
  font-size: 14px;
  color: #969696;
}
.media-img-wrapper .media-body > a {
  font-size: 14px;
  color: #969696;
  text-decoration: underline;
}
.media-img-wrapper .media-body > a:hover {
  color: #e9036e;
}
.media-center {
  text-align: center;
}
@media (min-width: 768px) {
  .media-center {
    text-align: left;
  }
}
.media-center .media-img {
  position: relative;
}
.media-center .media-img img {
  width: 100%;
}
.media-center .media-img .media-content {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  position: absolute;
  right: 15px;
  top: -50px;
}
.media-center .media-img .media-content span {
  font-size: 14px;
  top: 50%;
  position: relative;
  text-align: center;
  transform: translateY(-50%);
}
.media-sm {
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.media-sm img {
  border-radius: 0.25rem;
  margin-right: 11px;
}
.media-sm .media-body h6 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
}
.media-sm .media-body h6 a {
  color: #222;
}
.media-sm .media-body > a {
  color: #969696;
  font-size: 13px;
}
.media-list-view {
  background-color: #fff;
  border-radius: 5px;
}
.media-list-view .media-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .media-list-view .media-img {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
}
.media-list-view .media-img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.3s;
}
@media (min-width: 768px) {
  .media-list-view .media-img img {
    height: 247px;
  }
}
@media (min-width: 1200px) {
  .media-list-view .media-img img {
    height: auto;
  }
}
.media-list-view .media-img .media-img-overlay {
  transition: all 0.6s;
  transform: scale(1) rotate(0deg);
}
.media-list-view .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.media-list-view .media-body {
  padding: 1.25rem;
}
.media-list-view .media-body .media-title {
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}
.media-list-view .media-body .rating-view {
  display: flex;
  align-items: center;
  line-height: 15px;
  margin-bottom: 10px;
}
.media-list-view .media-body .rating-view .content-view {
  font-size: 13px;
  border-right: 1px solid #dedede;
  color: #969696;
  padding-right: 5px;
}
.media-list-view .media-body .rating-view .list-inline-rating {
  color: #fec701;
}
.media-list-view
  .media-body
  .rating-view
  .list-inline-rating
  .list-inline-item {
  margin-right: 0;
}
.media-list-view .media-body .date-view {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .media-list-view .media-body .date-view {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}
.media-list-view .media-body .date-view span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #dedede;
  padding: 0 14px;
  color: #969696;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}
.media-list-view .media-body .view-details {
  border-radius: 5px;
}
.media-list-view:hover .media-title {
  color: #e9036e;
}
.media-list-view.media-border .media-body {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (min-width: 768px) {
  .media-list-view.media-border .media-body {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
}
@media (min-width: 768px) {
  .media-list-view.media-border .media-body .date-view {
    position: absolute;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth {
    align-items: center;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth .media-img {
    width: 35%;
  }
}
@media (min-width: 1200px) {
  .media-list-fullwidth .media-img img {
    height: auto !important;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth .media-img img {
    height: 300px;
  }
}
.media-list-fullwidth .media-body {
  padding: 2rem;
}
@media (min-width: 1200px) {
  .media-list-fullwidth .media-body {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth .media-body {
    padding: 1.45rem;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth .media-body .date-view {
    position: relative;
  }
}
@media (min-width: 992px) {
  .media-list-fullwidth .media-body .date-view {
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .media-list-fullwidth.media-border .media-img {
    width: 32%;
  }
}
@media (min-width: 1200px) {
  .media-list-fullwidth.media-border .media-img img {
    height: 271px !important;
  }
}
@media (min-width: 768px) {
  .media-list-fullwidth.media-border .media-img img {
    height: 255px;
  }
}
.media-list .list-group .list-group-item {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #dedede;
  padding: 15px 0;
}
.media-list .list-group .list-group-item a {
  font-size: 13px;
  color: #969696;
}
.media-list-view-sm {
  padding: 9px;
  border-radius: 4px;
}
.media-list-view-sm > .media-body {
  width: 100%;
}
.media-list-view-sm > .media-body .media-list-sm {
  border-radius: 4px;
  border: 1px solid #eee;
}
.media-list-view-sm > .media-body .media-list-sm .media-img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.media-list-view-sm > .media-body .media-list-sm .media-img img {
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.media-list-view-sm
  > .media-body
  .media-list-sm
  .media-img:hover
  .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
.media-list-content {
  margin-bottom: 12px;
}
.media-list-content i {
  transform: rotate(45deg);
  font-size: 10px;
  color: #67686a;
  margin-right: 12px;
}
.media-profile-list {
  font-size: 13px;
  margin-bottom: 10px;
}
.media-profile-list strong {
  color: #343434;
}
@media (min-width: 768px) {
  .media-profile-list {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .media-profile-list {
    margin-bottom: 12px;
  }
}
@media (min-width: 1200px) {
  .media-profile-list {
    margin-bottom: 14px;
  }
}
.form-control {
  border-color: #e5e5e5;
}
.form-control::-moz-placeholder {
  color: #969696;
}
.form-control:-ms-input-placeholder {
  color: #969696;
}
.form-control::placeholder {
  color: #969696;
}
.form-control:focus {
  box-shadow: none;
  border-color: #e5e5e5;
  color: #969696;
}
.form-control-plaintext {
  outline: 0;
}
.form-control-white {
  border-color: #fff;
  color: #fff !important;
  background-color: transparent;
}
.form-control-white::-moz-placeholder {
  color: #fff;
}
.form-control-white:-ms-input-placeholder {
  color: #fff;
}
.form-control-white::placeholder {
  color: #fff;
}
.form-control-white:focus {
  background-color: transparent;
}
.form-transparent {
  background-color: transparent !important;
  border: 1px solid #4a4e52 !important;
  border-radius: 5px;
}
.form-search {
  background-color: transparent !important;
  border-color: rgba(155, 153, 152, 0.25) !important;
}
.form-subscribe .input-group-append {
  margin-left: -5px;
}
.input-group-append {
  margin-left: 0;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 3;
}
.form-group-icon-default,
.form-group-icon {
  position: relative;
}
.form-group-icon-default input.form-control,
.form-group-icon input.form-control {
  height: 45px;
}
.form-group-icon-default i,
.form-group-icon i {
  top: 50%;
  right: 2px;
  font-size: 0.81rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%);
}
.form-group-icon-default i {
  right: 13px;
  color: #969696;
}
.form-group-icon-category-2 i,
.form-group-icon-white i {
  color: #fff;
  right: 16px;
}
.form-group-icon-category-2 i,
.form-group-icon-dark i {
  color: #555;
}
.form-check-label {
  cursor: pointer;
}
.custom-control-label {
  outline: 0;
}
.custom-control-label span {
  color: #969696;
  margin-left: 8px;
  font-size: 13px;
}
.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-control-label::before,
.custom-control-label::after {
  width: 22px;
  height: 22px;
  top: 0;
  border-color: #dedede;
  box-shadow: none !important;
  outline: 0 !important;
}
.settings-control-label::before,
.settings-control-label::after {
  width: 14px;
  height: 14px;
  top: 4px;
}
.radio-control-label::before,
.radio-control-label::after {
  width: 16px;
  height: 16px;
  top: 2px;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::after {
  border-color: #dedede !important;
  background-color: transparent !important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  border-color: #dedede !important;
}
.count-control-label {
  font-size: 13px;
  color: #969696;
}
.count-input {
  position: relative;
  width: 140px;
}
@media (min-width: 768px) {
  .count-input {
    margin: auto;
  }
}
@media (min-width: 992px) {
  .count-input {
    margin-left: 0;
    width: 120px;
  }
}
@media (min-width: 1200px) {
  .count-input {
    width: 140px;
  }
}
.count-input .incr-btn {
  color: #666;
  display: block;
  font-size: 30px;
  font-weight: 300;
  height: 48px;
  line-height: 45px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 48px;
}
@media (min-width: 992px) {
  .count-input .incr-btn {
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .count-input .incr-btn {
    width: 48px;
  }
}
.count-input .incr-btn:first-child {
  left: 0;
  right: auto;
  border: #eaeaea;
}
.count-input .incr-btn:last-child {
  border: #eaeaea;
}
.count-input input {
  -moz-appearance: none;
  background: 0 0;
  border: 1px solid #eaeaea;
  border-radius: 0.25rem;
  height: 48px;
  font-weight: 700;
  color: #666;
  text-align: center;
  width: 100%;
}
.count-input input:focus {
  outline: 0;
}
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.icon-default {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}
.icon-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.icon-md {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 1.25rem;
  text-align: center;
}
.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px;
  font-size: 42px;
  text-align: center;
}
.icon-xl {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 3.125rem;
  text-align: center;
}
.icon-border {
  border: 1px solid #4a4e52;
}
.icon-light-border {
  border: 1px solid #e7e7e7;
}
.icon-setting {
  opacity: 0;
  transition: all 0.5s;
}
.icon-border-secondary {
  border: 1px solid #969696;
}
.icon-gray {
  color: #969696;
}
.icon-play {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 60px;
  color: #fff !important;
  line-height: 100px;
  padding-left: 10px;
}
.icon-item-default {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 35px;
  text-align: center;
}
.icon-contact-default {
  width: 75px;
  height: 75px;
  line-height: 75px;
  font-size: 35px;
  text-align: center;
}
.icon-social {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(0, 0, 0, 0.4);
}
.icon-medium {
  font-size: 1.12875rem !important;
}
.nav-menuzord {
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.4s;
}
@media (min-width: 992px) {
  .nav-menuzord {
    position: relative;
  }
}
.nav-menuzord .menuzord {
  background-color: transparent !important;
  padding: 0;
}
.nav-menuzord .menuzord .menuzord-brand {
  margin: 0 0 0 10px;
  padding: 27px 0;
  width: 150px;
}
@media (min-width: 768px) {
  .nav-menuzord .menuzord .menuzord-brand {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-brand {
    padding: 20px 0;
    width: auto;
  }
}
.nav-menuzord .menuzord .menuzord-brand img {
  width: 100%;
}
.nav-menuzord .menuzord .menuzord-logo-brand {
  background-image: url(../img/logo.png);
  background-position: 15px 20px;
  background-size: 150px;
  background-repeat: no-repeat;
  width: 230px;
  height: 80px;
}
@media (min-width: 768px) {
  .nav-menuzord .menuzord .menuzord-logo-brand {
    background-size: auto;
    background-position: 15px 15px;
  }
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-logo-brand {
    background-position: 15px 20px;
  }
}
.nav-menuzord .menuzord .showhide {
  width: 50px;
  height: 40px;
  padding: 10px 0 0;
  background-color: #e9036e;
  border-radius: 0.25rem;
  margin-top: 25px !important;
  margin-right: 10px !important;
}
@media (min-width: 768px) {
  .nav-menuzord .menuzord .showhide {
    margin-right: 0 !important;
  }
}
.nav-menuzord .menuzord .showhide em {
  background-color: #fff;
  margin: 3px 15px 0;
}
.nav-menuzord .menuzord .menuzord-menu .indicator {
  width: 100%;
  border-left: solid 1px transparent;
  margin-left: 0;
}
.nav-menuzord .menuzord .menuzord-menu .indicator i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu {
    padding-right: 50px;
  }
  .nav-menuzord .menuzord .menuzord-menu .indicator {
    display: none;
  }
}
.nav-menuzord .menuzord .menuzord-menu ul.dropdown .indicator {
  display: block;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown .indicator i {
    top: 3px;
    right: 0;
    transform: translateY(0);
  }
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown {
    border-radius: 5px;
    border-top: solid 1px #f0f0f0;
    padding: 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown {
    border-left: 3px solid #e9036e;
    width: 100%;
  }
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown::before {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 7px solid #e9036e;
    top: 20px;
    margin-top: -8px;
    content: "";
    display: inline-block;
    left: -10px;
    position: absolute;
  }
}
@media (min-width: 992px) {
  .nav-menuzord
    .menuzord
    .menuzord-menu
    ul.dropdown
    li
    ul.dropdown.drop-sub-menu-left {
    left: -100%;
  }
}
@media (min-width: 1200px) {
  .nav-menuzord
    .menuzord
    .menuzord-menu
    ul.dropdown
    li
    ul.dropdown.drop-sub-menu-left {
    left: 100%;
  }
}
.nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a {
  transition: all 0.3s;
  border-radius: 5px;
  color: #969696;
}
.nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a:hover {
  background-color: transparent !important;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a:hover {
    background-color: #f5f5f5 !important;
  }
}
.nav-menuzord .menuzord .menuzord-menu li.active > a:after {
  width: 30%;
}
.nav-menuzord .menuzord .menuzord-menu li:hover > a:after {
  width: 100%;
}
.nav-menuzord .menuzord .menuzord-menu li.active > a,
.nav-menuzord .menuzord .menuzord-menu li:hover > a {
  color: #e9036e !important;
}
.nav-menuzord .menuzord .menuzord-menu > li > a {
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > a {
    padding: 37px 0;
    margin: 0 11px;
    position: relative;
  }
  .nav-menuzord .menuzord .menuzord-menu > li > a:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 30px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 300ms ease-out;
  }
  .nav-menuzord .menuzord .menuzord-menu > li > a:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 28px;
    width: 0;
    height: 3px;
    background-color: #e9036e;
    transition: all 300ms ease-out;
    border-radius: 2px;
  }
}
@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-menu > li > a {
    padding: 37px 0;
    margin: 0 15px;
  }
}
.nav-menuzord .menuzord .menuzord-menu > li > .megamenu {
  padding: 0;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > .megamenu {
    border-radius: 5px;
    padding: 10px 30px 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}
.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li {
  transition: all 0.3s;
  border-bottom: solid 1px #f0f0f0;
  padding-left: 40px;
}
.nav-menuzord
  .menuzord
  .menuzord-menu
  > li
  > .megamenu
  .list-unstyled
  li:hover {
  background: #f5f5f5;
}
.nav-menuzord
  .menuzord
  .menuzord-menu
  > li
  > .megamenu
  .list-unstyled
  li.active
  > a {
  color: #e9036e;
}
.nav-menuzord
  .menuzord
  .menuzord-menu
  > li
  > .megamenu
  .list-unstyled
  li:first-child {
  background-color: transparent !important;
  padding: 12px 20px;
  padding-left: 40px;
}
@media (min-width: 992px) {
  .nav-menuzord
    .menuzord
    .menuzord-menu
    > li
    > .megamenu
    .list-unstyled
    li:first-child {
    padding-left: 0;
  }
}
.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li a {
  display: block;
  color: #969696;
  font-size: 12px;
  padding: 10px 25px 10px 0;
  transition: all 0.3s;
}
.nav-menuzord
  .menuzord
  .menuzord-menu
  > li
  > .megamenu
  .list-unstyled
  li
  a:hover {
  margin-left: 8px;
  color: #e9036e;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li {
    border-bottom: 0;
    padding-left: 0;
  }
}
.nav-menuzord
  .menuzord
  .menuzord-menu
  > li
  > .megamenu
  .megamenu-row
  [class*="col"] {
  margin-top: 0;
}
.nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
  text-align: center;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
    display: inline-block;
    padding-left: 12px;
  }
}
@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
    padding-left: 15px;
  }
}
.nav-menuzord .menuzord .menuzord-menu .menuzord-btn .btn {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 7px 19px;
  margin: 15px 0;
  transition: all 0.3s;
}
@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn .btn {
    margin-top: 27px;
  }
}
.nav-menuzord .nav-item.dropdown {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
}
@media (min-width: 992px) {
  .nav-menuzord .nav-item.dropdown {
    display: inline-block !important;
  }
}
.nav-menuzord .nav-item.dropdown .dropdown-menu {
  width: 350px;
  padding: 15px 20px;
  margin-top: 27px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.nav-menuzord .nav-item.dropdown > .btn {
  padding: 10px 12px;
}
li.default-nav-item > .btn {
  margin: 0 !important;
  background-color: #e9036e !important;
  border-color: #e9036e !important;
}
li.default-nav-item > .btn i {
  color: #fff !important;
}
.nav-menuzord .menuzord .menuzord-menu > li.default-nav-item > a:before,
.nav-menuzord .menuzord .menuzord-menu > li.default-nav-item > a:after {
  background-color: transparent !important;
}
.static .navbar-sticky {
  display: none;
}
.up-scroll .navbar-scrollUp.navbar-sticky {
  transform: translateY(-100%);
  transition: 0.4s ease-in-out;
  box-shadow: none;
}
.nav-menuzord-transparent {
  position: absolute;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}
.nav-menuzord-transparent .menuzord-menu {
  background-color: #fff;
  padding: 0 20px;
}
@media (min-width: 992px) {
  .nav-menuzord-transparent .menuzord-menu {
    background-color: transparent;
  }
  .nav-menuzord-transparent .menuzord-menu > li > a {
    color: #fff !important;
  }
  .nav-menuzord-transparent .menuzord-menu > li > a:before {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}
.navbar-sticky {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
}
.navbar-sticky.nav-menuzord {
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.navbar-sticky.nav-menuzord .menuzord .menuzord-logo-brand {
  background-image: url(../img/logo-color-big.png);
}
@media (min-width: 992px) {
  .navbar-sticky.nav-menuzord .menuzord-menu > li > a {
    color: #000 !important;
  }
  .navbar-sticky.nav-menuzord .menuzord-menu > li > a:before {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
.section-title,
.section-title2 {
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .section-title,
  .section-title2 {
    margin-bottom: 48px;
  }
}
.section-title:before,
.section-title2:before {
  content: "";
  top: 11px;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  background-color: #e5e5e5;
}
@media (min-width: 768px) {
  .section-title:before,
  .section-title2:before {
    top: 14px;
  }
}
.section-title span,
.section-title2 span {
  padding-left: 38px;
  padding-right: 38px;
}
.section-title h2,
.section-title2 h2 {
  font-size: 20px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .section-title h2,
  .section-title2 h2 {
    font-size: 26px;
  }
}
.section-title p,
.section-title2 p {
  font-size: 14px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .section-title p,
  .section-title2 p {
    font-size: 18px;
  }
}
.section-title2 span {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.section-title2:before {
  display: none;
}
@media (min-width: 768px) {
  .section-title-bg {
    background-image: url(../img/home/devider.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 8px 45px;
  }
}
.section-title-bg h1 {
  font-size: 14px;
}
@media (min-width: 992px) {
  .section-title-bg h1 {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .section-title-bg h1 {
    font-size: 20px;
  }
}
.section-title-bg h2 {
  font-size: 30px;
}
@media (min-width: 992px) {
  .section-title-bg h2 {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .section-title-bg h2 {
    font-size: 26px;
  }
}
.section-top {
  padding-top: 94px;
}
@media (min-width: 992px) {
  .section-top {
    padding-top: 0;
  }
}
.element-title {
  margin-bottom: 1rem;
}
.select2-select {
  width: 100%;
}
.select2-containerPublic--default .select2-selection--single:focus {
  outline: 0;
}
.select-dropdown-top {
  margin-top: 0;
}
.select-dropdown-top .select2-containerPublic--default .select2-selection--single {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  position: relative;
}
.select-dropdown-top
  .select2-containerPublic--default
  .select2-selection--single:before {
  position: absolute;
  content: "\f107";
  font-family: fontawesome;
  font-style: normal;
  right: 0;
  color: #fff;
}
.select-dropdown-top
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 100%;
  left: 0;
  display: none;
}
.select-dropdown-top
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  padding-left: 20px;
}
.select-dropdown-top
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--single,
.select-dropdown-top
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select-dropdown {
  transition: all 0.9s;
}
.select-dropdown .select2-containerPublic--default .select2-selection--single {
  transition: all 0.9s;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  position: relative;
}
.select-dropdown .select2-containerPublic--default .select2-selection--single:before {
  position: absolute;
  content: "\f107";
  font-family: fontawesome;
  font-style: normal;
  right: 0;
  color: rgba(255, 255, 255, 0.3);
}
.select-dropdown
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 100%;
  left: 0;
  display: none;
}
.select-dropdown
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.3);
  font-size: 13px;
  font-weight: 400;
  line-height: 45px;
  padding-left: 0;
}
.select-dropdown
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--single,
.select-dropdown
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select-dropdown.text-color
  .select2-containerPublic--default
  .select2-selection--single {
  border-bottom: 1px solid #dedede !important;
}
.select-dropdown.text-color
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__rendered,
.select-dropdown.text-color
  .select2-containerPublic--default
  .select2-selection--single:before {
  color: #969696 !important;
}
.select-dropdown-option .select2-containerPublic--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  position: relative;
}
.select-dropdown-option
  .select2-containerPublic--default
  .select2-selection--single:before {
  position: absolute;
  content: "\f017";
  font-family: fontawesome;
  font-style: normal;
  right: 20px;
  color: #969696;
}
.select-dropdown-option
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 100%;
  left: 0;
  display: none;
}
.select-dropdown-option
  .select2-containerPublic--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #969696;
  font-weight: 400;
  line-height: 50px;
  padding-left: 20px;
}
.select-dropdown-option
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--single,
.select-dropdown-option
  .select2-containerPublic--default.select2-containerPublic--open.select2-containerPublic--below
  .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-search--dropdown {
  display: none;
}
.select2-containerPublic--open .select2-dropdown--below {
  border: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.select2-results__option[aria-selected] {
  border-bottom: 1px solid #ddd;
  font-family: open sans, sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: capitalize;
  transition: all 0.4s;
  color: #969696;
}
.select2-containerPublic--default .select2-results__option[aria-selected="true"] {
  background-color: #fff;
  color: #969696;
}
.select2-containerPublic--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fff;
  color: #969696;
}
.select2-containerPublic--default
  .select2-results__option--highlighted[aria-selected]:hover {
  color: #e9036e;
  background-color: #f8f9fa;
}
.select2-containerPublic--default .select2-results > .select2-results__options {
  color: #969696;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #e9036e;
  border-color: transparent;
  color: #fff;
}
.daterange-picker {
  padding: 0;
  outline: 0;
  font-size: 13px;
  border-radius: 0;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.3);
  font-weight: 400;
  border-color: transparent !important;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.daterange-picker::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.daterange-picker:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.daterange-picker::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.sidebar-daterange-picker {
  color: #969696 !important;
  border-bottom: 1px solid #dedede !important;
}
.sidebar-daterange-picker::-moz-placeholder {
  color: #969696 !important;
}
.sidebar-daterange-picker:-ms-input-placeholder {
  color: #969696 !important;
}
.sidebar-daterange-picker::placeholder {
  color: #969696 !important;
}
.daterange-picker-white {
  border-radius: 5px;
  color: #fff !important;
  text-transform: uppercase;
  border-color: #fff !important;
  background-color: transparent !important;
  padding: 0.71rem 1rem;
  font-family: sans-serif;
}
.daterange-picker-white::-moz-placeholder {
  color: #fff;
}
.daterange-picker-white:-ms-input-placeholder {
  color: #fff;
}
.daterange-picker-white::placeholder {
  color: #fff;
}
.daterange-picker-category-2 {
  border-radius: 3px;
  color: #666 !important;
  text-transform: uppercase;
  border-color: #dedede !important;
  background-color: transparent !important;
  padding: 0.71rem 1rem;
  font-family: sans-serif;
}
.daterange-picker-category-2::-moz-placeholder {
  color: #969696;
}
.daterange-picker-category-2:-ms-input-placeholder {
  color: #969696;
}
.daterange-picker-category-2::placeholder {
  color: #969696;
}
.card {
  transition: all 0.3s;
}
.card img {
  transition: all 0.3s;
}
.card .card-header {
  padding: 0.93rem 1.5rem;
}
.card .card-title {
  font-size: 15px;
  font-weight: 700;
  color: #222;
  transition: all 0.3s;
}
.card:hover .card-title {
  color: #e9036e;
}
.card-lg img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;
}
@media (min-width: 768px) {
  .card-lg img {
    height: 400px;
  }
}
@media (min-width: 992px) {
  .card-lg img {
    height: 350px;
  }
}
@media (min-width: 1200px) {
  .card-lg img {
    height: auto;
  }
}
.card-md .card-body {
  padding-top: 43px;
  padding-bottom: 43px;
}
.card-hover:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.card-bg {
  background-color: #f5f5f5;
}
.card-bg:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.card-bg-white {
  padding: 70px 0 40px;
}
.card-bg-white .card-body {
  padding: 1.8rem;
}
.card-bg-white .card-body h6 {
  line-height: 26px;
  margin-bottom: 25px;
}
.card-bg-white .card-body a {
  font-size: 13px;
  color: #969696;
  text-decoration: underline;
  transition: all 0.3s;
}
.card-bg-white .card-body a:hover {
  color: #e9036e;
}
@media (min-width: 768px) {
  .card-opacity img {
    width: auto;
  }
}
@media (min-width: 992px) {
  .card-opacity img {
    width: 100%;
  }
}
.card-opacity .card-body {
  padding-top: 15px;
}
.card-opacity .card-body h5 > a {
  margin-bottom: 5px;
}
.card-opacity:hover .card-hover-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
.card-transparent {
  background-color: transparent;
}
.card-transparent .card-footer {
  padding: 0;
  background-color: transparent;
}
.card-hover-overlay {
  transition: all 0.5s;
}
.card-hover-overlay:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.card-hoverable {
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
}
@media (min-width: 992px) {
  .card-hoverable:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .card-hoverable:hover .top-content {
    bottom: 185px;
    margin-bottom: 0;
  }
  .card-hoverable:hover .bottom-content {
    opacity: 1;
    visibility: visible;
    transition: all 0.99s;
  }
}
@media (min-width: 992px) {
  .card-hoverable {
    background-color: transparent;
  }
}
.card-hoverable .top-content {
  transition: all 0.3s ease-in-out;
  bottom: 185px;
  position: absolute;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .card-hoverable .top-content {
    bottom: 0;
    margin-bottom: 30px;
  }
}
.card-hoverable .bottom-content {
  transition: all 0.2s;
}
@media (min-width: 992px) {
  .card-hoverable .bottom-content {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
  }
}
.card-bg-img .card-img-top {
  height: auto;
}
@media (min-width: 768px) {
  .card-bg-img .card-img-top {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .card-bg-img .card-img-top {
    height: 450px;
  }
}
.meta-post-sm .meta-tag {
  font-size: 12px;
}
.icon-badge {
  display: inline-block;
  text-align: center;
  position: relative;
}
.icon-badge .badge {
  position: absolute;
  top: -5px;
  right: -10px;
  padding: 0.1875rem 0.44rem;
  border-radius: 0.625rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.badge-rounded,
.badge-rounded-circle {
  left: 20px;
  top: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.badge-rounded span,
.badge-rounded-circle span {
  font-size: 18px;
  text-transform: uppercase;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
.badge-rounded {
  font-size: 1rem;
  padding: 0.89rem 1.375rem;
  color: #fff;
  line-height: 1;
  font-weight: 700;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.badge-rounded-circle {
  padding: 0;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  box-shadow: none;
  text-align: center;
}
.badge-large {
  position: absolute;
  font-size: 1.25rem;
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 0.625rem;
}
@media (min-width: 992px) {
  .badge-large {
    font-size: 1.88rem;
    padding: 0.4375rem 1.8rem;
  }
}
.badge-large-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  top: 15px;
}
@media (min-width: 992px) {
  .badge-large-left {
    top: 55px;
  }
}
.badge-large-right {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  top: 15px;
  right: 0;
}
@media (min-width: 992px) {
  .badge-large-right {
    top: 55px;
  }
}
.badge-medium-center,
.badge-large-center {
  border-radius: 0.375rem;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
}
.badge-medium {
  position: absolute;
  font-size: 1.125rem;
  color: #fff;
  text-transform: capitalize;
  padding: 0.937rem 0;
  width: 100%;
}
@media (min-width: 768px) {
  .badge-medium {
    font-size: 1.25rem;
    padding: 0.937rem 2.81rem;
    width: auto;
  }
}
@media (min-width: 992px) {
  .badge-medium {
    padding: 22px 33px;
    bottom: 30px;
  }
  .badge-medium span {
    font-size: 1.5625rem;
  }
}
.badge-sm {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
  background-color: rgba(0, 0, 0, 0.06);
}
.badge-sm span {
  font-size: 30px !important;
}
.badge-primary {
  color: #fff;
}
.badge-primary:hover {
  color: #fff !important;
}
.badge-black-Offset {
  background-color: rgba(0, 0, 0, 0.35);
}
.badge-hover {
  transition: all 0.3s;
}
.badge-hover:hover {
  opacity: 0.8;
}
.badge-pill-sm {
  font-weight: 400;
  border: 1px solid #dedede;
  color: #969696;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  text-transform: uppercase;
}
.counter-up .icon.bg-primary {
  background-color: #e9036e !important;
}
.counter-up .icon.bg-secondary {
  background-color: rgba(108, 117, 125, 0.8) !important;
}
.counter-up .icon.bg-success {
  background-color: rgba(96, 166, 98, 0.8) !important;
}
.counter-up .icon.bg-danger {
  background-color: rgba(226, 90, 90, 0.8) !important;
}
.counter-up .icon.bg-warning {
  background-color: rgba(254, 199, 1, 0.8) !important;
}
.counter-up .icon.bg-info {
  background-color: rgba(26, 179, 232, 0.8) !important;
}
.counter-up h1 {
  font-size: 32px;
  color: #fff;
}
@media (min-width: 992px) {
  .counter-up h1 {
    font-size: 52px;
  }
}
.counter-up h6 {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .counter-up h6 {
    font-size: 15px;
  }
}
.smooth-loader-wrapper {
  background-color: #e9036e;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
}
.smooth-loader-wrapper .smooth-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2100;
  line-height: 0;
  transform: translate(-50%, -50%);
}
.sk-circle {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
}
@media (min-width: 768px) {
  .sk-circle {
    width: 60px;
    height: 60px;
  }
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  to {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  to {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.loaded #preloader {
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .loaded #preloader {
    transition: none;
  }
}
.dzsparallaxer:not(.mode-oneelement) {
  transition-duration: 0.9s;
}
.dzsparallaxer.use-loading .dzsparallaxer--target {
  transition-duration: 0.9s;
}
.paralax-containerPublic {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.9s;
}
.promotion-paralax {
  height: 403px !important;
  transition: all 0.9s;
}
.counterup-paralax {
  height: 550px !important;
  transition: all 0.9s;
}
@media (min-width: 768px) {
  .counterup-paralax {
    height: 452px !important;
  }
}
.city-paralax {
  height: 440px !important;
}
@media (min-width: 768px) {
  .city-paralax {
    height: 359px !important;
  }
}
.white-paralax {
  height: 600px !important;
}
@media (min-width: 768px) {
  .white-paralax {
    height: 450px !important;
  }
}
.signup-paralax {
  height: 450px !important;
}
@media (min-width: 768px) {
  .signup-paralax {
    height: 400px !important;
  }
}
@media (min-width: 992px) {
  .signup-paralax {
    height: 386px !important;
  }
}
.travel-paralax {
  height: 600px !important;
}
@media (min-width: 768px) {
  .travel-paralax {
    height: 524px !important;
  }
}
.video-paralax {
  height: 350px !important;
}
@media (min-width: 768px) {
  .video-paralax {
    height: 550px !important;
  }
}
.sale-paralax {
  height: 400px !important;
}
.modal-sm .modal-content {
  border-radius: 6px;
}
.modal-sm .modal-content .modal-header {
  border-top: 6px solid #e9036e;
  border-bottom: 0;
  padding: 10px 20px;
}
.modal-sm .modal-content .modal-header .close {
  font-size: 30px;
  color: #222;
  opacity: 1;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.modal-sm .modal-content .modal-header .close:focus {
  outline: 0;
}
.modal-sm .modal-content .modal-body {
  padding: 10px 20px;
}
.modal-sm .modal-content .modal-footer {
  border-top: 0;
}
.gallery-slider .slick-prev,
.gallery-slider .slick-next {
  display: none !important;
}
@media (min-width: 768px) {
  .gallery-slider .slick-prev,
  .gallery-slider .slick-next {
    display: block !important;
  }
}
.gallery-slider .slick-prev {
  left: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .gallery-slider .slick-prev {
    left: -60px;
    right: auto;
  }
}
@media (min-width: 992px) {
  .gallery-slider .slick-prev {
    left: -80px;
  }
}
.gallery-slider .slick-prev:before {
  content: "\f053";
  font-family: fontawesome;
  color: #e9036e;
  font-size: 25px;
  display: block;
}
@media (min-width: 768px) {
  .gallery-slider .slick-prev:before {
    display: block;
    color: #a5a5a5;
  }
}
.gallery-slider .slick-next {
  right: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .gallery-slider .slick-next {
    right: -60px;
  }
}
@media (min-width: 992px) {
  .gallery-slider .slick-next {
    right: -80px;
  }
}
.gallery-slider .slick-next:before {
  content: "\f054";
  font-family: fontawesome;
  color: #e9036e;
  font-size: 25px;
  display: block;
}
@media (min-width: 768px) {
  .gallery-slider .slick-next:before {
    display: block;
    color: #a5a5a5;
  }
}
.gallery-slider .item {
  margin: 0;
  outline: 0;
  z-index: 0 !important;
  transform: scale(1) rotate(0);
}
@media (min-width: 768px) {
  .gallery-slider .item {
    transform: scale(0.8) rotate(0);
    opacity: 0.3;
    margin: 0 -100px;
    transition: transform 0.4s ease;
  }
}
.gallery-slider .item .slick-img img {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .gallery-slider .item .slick-img img {
    width: 525px;
  }
}
@media (min-width: 992px) {
  .gallery-slider .item .slick-img img {
    width: 725px;
  }
}
@media (min-width: 1200px) {
  .gallery-slider .item .slick-img img {
    width: auto;
  }
}
.gallery-slider .item.slick-active {
  transform: scale(1) rotate(0);
  position: relative;
  opacity: 1;
  z-index: 1 !important;
}
@media (min-width: 768px) {
  .gallery-slider .item.slick-active .slick-img img {
    border: 5px solid #e9036e !important;
    border-radius: 3px;
  }
}
.brand-slider .item {
  outline: 0;
}
.brand-slider .slick-prev {
  z-index: 1;
  right: auto;
  left: 0;
}
.brand-slider .slick-prev:before {
  font-family: fontawesome;
  content: "\f104";
  color: #a5a5a5;
  font-size: 25px;
}
.brand-slider .slick-next {
  z-index: 1;
  right: 0;
}
.brand-slider .slick-next:before {
  content: "\f105";
  font-family: fontawesome;
  color: #a5a5a5;
  font-size: 25px;
}
.brand-slider .slick-list .slick-track .slick-slide img {
  margin: 0 auto;
}
.select-default .selectric {
  background-color: transparent;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
.select-default .selectric .label {
  padding: 0.8rem 1.5rem;
  font-size: 0.875rem;
  color: #969696;
  text-transform: uppercase;
  font-family: montserrat, sans-serif;
}
.select-default .selectric .button {
  height: 42px;
  width: 46px;
  background: 0 0;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
}
.select-default .selectric .button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: fontawesome;
  content: "\f107";
  font-size: 1rem;
  color: #969696;
}
.select-default .selectric-open .selectric {
  margin-top: 0;
}
.select-default .selectric-items {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border-color: #dee2e6;
}
.select-default .selectric-items::before,
.select-default .selectric-items::after {
  display: none;
}
.select-default .selectric-items .selectric-scroll li {
  border-bottom: 1px solid #dee2e6;
  padding: 13px 30px 13px 15px;
  font-size: 12px;
  color: #969696;
  text-transform: uppercase;
}
.select-default .selectric-items .selectric-scroll li:hover {
  background-color: #edeff1;
  color: #e9036e;
}
.select-default .selectric-items .selectric-scroll li.highlighted {
  background-color: #edeff1;
  color: #e9036e;
}
.select-default .selectric-items .selectric-scroll li.highlighted::after {
  display: none;
}
.select-confirm .selectric {
  background-color: #f5f5f5;
  border: 0;
}
.select-white .selectric {
  background-color: transparent;
  border-radius: 5px;
}
.select-white .selectric .label {
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 14px;
}
.select-white .selectric .button {
  background-color: transparent;
}
.select-white .selectric .button::before {
  color: #fff;
}
.select-white .selectric-items {
  border-radius: 5px;
}
.select-white .selectric-items .selectric-scroll li {
  font-size: 14px;
}
.select-category-1 .selectric {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #dedede;
  border-radius: 0;
}
.select-category-1 .selectric .label {
  font-size: 12px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.select-category-1 .selectric .button {
  width: auto;
  right: 5px;
}
.select-category-1 .selectric-items {
  border-radius: 5px;
}
.select-category-1 .selectric-items .selectric-scroll li {
  font-size: 13px;
}
.select-category-2 .selectric {
  background-color: transparent;
  border-color: #dedede;
  border-radius: 3px;
}
.select-category-2 .selectric .label {
  color: #666;
  font-size: 13px;
}
.select-category-2 .selectric .button {
  background-color: transparent;
}
.select-category-2 .selectric .button::before {
  color: #666;
}
.select-category-2 .selectric-items {
  border-radius: 5px;
}
.select-category-2 .selectric-items .selectric-scroll li {
  font-size: 13px;
}
.timer .selectric .button::before {
  content: "\f017";
  font-size: 13px;
}
.select-search-box .selectric {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
.select-search-box .selectric .label {
  font-size: 13px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.3);
}
.select-search-box .selectric .button {
  width: auto;
  right: 5px;
}
.select-search-box .selectric .button::before {
  color: rgba(255, 255, 255, 0.3);
}
.select-search-box .selectric-items {
  border-radius: 5px;
}
.select-search-box .selectric-items .selectric-scroll li {
  font-size: 13px;
}
.select-features .selectric .label {
  text-transform: capitalize;
}
.page-title {
  padding-top: 94px;
}
@media (min-width: 992px) {
  .page-title {
    padding-top: 20;
  }
}
.page-title .page-title-img {
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat;
  transition: all 0.3s linear;
  position: relative;
}
.page-title .page-title-content {
  text-align: center;
  position: relative;
}
.page-title .page-title-content h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: inherit;
}
.page-title .page-title-content p {
  font-size: 15px;
}
.page-title .title-border {
  position: relative;
}
.page-title .title-border h2 {
  margin-bottom: 23px;
}
.page-title .title-border::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 3px;
  background-color: #fff;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
}
.price-range .price-range-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .price-range .price-range-content {
    flex-wrap: nowrap;
  }
}
.price-range .price-text,
.price-range .price-value {
  color: #222;
  font-weight: 600;
}
.price-range .btn {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .price-range .btn {
    margin-right: 0;
  }
}
.noUi-target {
  background-color: #dedede;
  box-shadow: none;
  border: 0;
}
.noUi-horizontal {
  height: 4px;
  border-radius: 0.25rem;
}
.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #e9036e;
  box-shadow: none;
  border: 0;
  border-radius: 50%;
  top: -7px;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-connect {
  background-color: #e9036e;
}
.noUi-horizontal .noUi-origin:focus,
.noUi-handle.noUi-handle-lower:focus,
.noUi-handle.noUi-handle-upper:focus {
  outline: 0;
}
.noUi-horizontal .noUi-handle {
  right: -15px !important;
  cursor: e-resize;
}
.noUi-horizontal .noUi-handle.noUi-handle-lower {
  left: auto !important;
}
.noUi-horizontal .noUi-handle.noUi-handle-upper {
  right: -1px !important;
}
.pagination {
  margin-bottom: 0;
}
.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  border: 1px solid #dedede;
  margin: 0;
}
@media (min-width: 768px) {
  .pagination .page-item:first-child .page-link,
  .pagination .page-item:last-child .page-link {
    padding: 0.5rem 1.3rem;
  }
}
.pagination .page-item:first-child .page-link:hover,
.pagination .page-item:last-child .page-link:hover {
  color: #fff;
  background-color: #e9036e;
  border-color: #e9036e;
}
.pagination .page-item .page-link {
  color: #222;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 10px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .pagination .page-item .page-link {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 1px;
  }
}
.pagination .page-item .page-link:hover {
  color: #e9036e;
}
.pagination .page-item .page-link.active {
  color: #e9036e;
  cursor: default;
}
.owl-theme .owl-dots {
  margin-top: 0 !important;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: transparent;
  border: 1px solid #fff;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #e9036e;
  border: 1px solid #e9036e;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #e9036e;
  border: 1px solid #e9036e;
}
.owl-theme .owl-dots .owl-dot:focus {
  outline: 0;
}
.package-slider .owl-stage-outer {
  border-radius: 5px;
}
.package-slider.owl-theme .owl-nav {
  margin-top: 00;
  display: none;
}
@media (min-width: 768px) {
  .package-slider.owl-theme .owl-nav {
    display: block;
  }
}
.package-slider.owl-theme .owl-nav .owl-prev,
.package-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 100%;
}
.package-slider.owl-theme .owl-nav .owl-prev {
  left: 20px;
}
.package-slider.owl-theme .owl-nav .owl-next {
  right: 20px;
}
.package-slider.owl-theme .owl-nav [class*="owl-"] {
  margin-top: 0;
  font-size: 1.25rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0;
  top: 50%;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.package-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.package-slider img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .package-slider img {
    height: auto;
  }
}
@media (min-width: 768px) {
  .package-slider.package-single img {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .package-slider.package-single img {
    height: 450px;
  }
}
.gallery-photo-slider .owl-stage-outer {
  border-radius: 5px;
}
.gallery-photo-slider.owl-theme .owl-nav {
  margin-top: 0;
  display: none;
}
@media (min-width: 768px) {
  .gallery-photo-slider.owl-theme .owl-nav {
    display: block;
  }
}
.gallery-photo-slider.owl-theme .owl-nav .owl-prev,
.gallery-photo-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 100%;
}
.gallery-photo-slider.owl-theme .owl-nav .owl-prev {
  left: 20px;
}
.gallery-photo-slider.owl-theme .owl-nav .owl-next {
  right: 20px;
}
.gallery-photo-slider.owl-theme .owl-nav [class*="owl-"] {
  margin-top: 0;
  font-size: 1.25rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 4px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0;
  top: 50%;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.gallery-photo-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.gallery-photo-slider img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .gallery-photo-slider img {
    height: auto;
  }
}
.owl-slider-tabs {
  bottom: 30px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
@media (min-width: 768px) {
  .owl-slider-tabs {
    display: block;
  }
}
.owl-slider-tabs li {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-right: 5px;
}
.owl-slider-tabs li:last-child {
  margin-right: 0;
}
.owl-slider-tabs li img {
  height: auto;
}
.slidetabs {
  opacity: 0.5;
}
.slidetabs.active {
  opacity: 1;
}
.progress-wizard {
  margin: 0 -10px;
}
@media (min-width: 768px) {
  .progress-wizard {
    margin: 0;
  }
}
.progress-wizard > .progress-wizard-step {
  padding: 0;
  position: relative;
}
@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 0;
  }
}
.progress-wizard > .progress-wizard-step > .progress {
  position: relative;
  border-radius: 0;
  height: 2px;
  box-shadow: none;
  margin: 0;
  background-color: #dedede;
  top: 50%;
}
.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  box-shadow: none;
  background-color: #e9036e;
}
@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background-color: #e9036e;
  }
}
.progress-wizard > .progress-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}
.progress-wizard > .progress-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0%;
}
.progress-wizard
  > .progress-wizard-step:first-child.active
  > .progress
  > .progress-bar {
  width: 0%;
}
.progress-wizard
  > .progress-wizard-step:last-child.active
  > .progress
  > .progress-bar {
  width: 100%;
}
.progress-wizard > .progress-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.progress-wizard > .progress-wizard-step:last-child > .progress {
  width: 50%;
}
.progress-wizard > .progress-wizard-step.disabled a.progress-wizard-dot {
  pointer-events: none;
}
.progress {
  background-color: transparent;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .progress {
    background-color: #dedede;
  }
}
.progress-wizard > .progress-wizard-step > .progress-wizard-dot {
  display: block;
  background-color: #fff;
  border-radius: 7px;
  border: 2px solid #ededed;
  text-align: center;
  color: #969696;
  width: 85%;
  height: 60px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    width: 203px;
    height: 102px;
    border-radius: 10px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.progress-wizard
  > .progress-wizard-step
  > .progress-wizard-dot
  .progress-wizard-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.progress-wizard
  > .progress-wizard-step
  > .progress-wizard-dot
  .progress-wizard-content
  i {
  font-size: 13px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .progress-wizard
    > .progress-wizard-step
    > .progress-wizard-dot
    .progress-wizard-content
    i {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
.progress-wizard
  > .progress-wizard-step
  > .progress-wizard-dot
  .progress-wizard-content
  span {
  font-size: 10px;
  font-weight: 500;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .progress-wizard
    > .progress-wizard-step
    > .progress-wizard-dot
    .progress-wizard-content
    span {
    font-size: 14px;
    font-weight: 400;
  }
}
.progress-wizard > .progress-wizard-step > .progress-wizard-dot:hover {
  opacity: 1;
}
.progress-wizard > .progress-wizard-step.active > .progress-wizard-dot {
  border-color: #e9036e;
  color: #222;
  cursor: default;
}
.progress-wizard > .progress-wizard-step.incomplete > .progress-wizard-dot i {
  color: #222;
}
.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot {
  background-color: #e9036e;
  border-color: #e9036e;
}
.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot i,
.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot span {
  color: #fff;
}
.progress-wizard .progress-info {
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: #222;
}
.alert-confirm {
  padding: 0;
  border-left: 0;
}
.alert-confirm i {
  border-radius: 4px 0 0 4px;
  font-size: 20px;
  color: #fff;
  padding: 35px 15px;
  width: 50px;
  text-align: center;
}
.alert-confirm > span {
  padding-left: 15px;
  padding-right: 80px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .alert-confirm > span {
    font-size: 16px !important;
  }
}
.alert-confirm .close {
  text-shadow: none;
  opacity: 1;
  z-index: 1;
}
.alert-confirm .close:hover {
  color: inherit;
}
.alert-confirm .close:focus {
  outline: 0;
}
.alert-confirm > span,
.alert-confirm .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .alert-confirm > span,
  .alert-confirm .close {
    font-size: 14px;
  }
}
.alert-confirm.alert-success i {
  background-color: #60a662;
}
.alert-confirm.alert-success .close {
  color: #60a662;
}
.alert-confirm.alert-danger i {
  background-color: #e25a5a;
}
.alert-confirm.alert-danger .close {
  color: #e25a5a;
}
.alert-confirm.alert-primary i {
  background-color: #e9036e;
}
.alert-confirm.alert-primary .close {
  color: #e9036e;
}
.alert-confirm.alert-warning i {
  background-color: #fec701;
}
.alert-confirm.alert-warning .close {
  color: #fec701;
}
.alert-confirm.alert-info i {
  background-color: #1ab3e8;
}
.alert-confirm.alert-info .close {
  color: #1ab3e8;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}
.alert-default {
  padding: 0;
  border-left: 0;
}
.alert-default i {
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  color: #fff;
  padding: 25px 0;
  text-align: center;
  opacity: 0;
  width: -webkit-fill-available;
}
@media (min-width: 768px) {
  .alert-default i {
    opacity: 1;
    width: 50px;
    padding: 37px 0;
  }
}
@media (min-width: 992px) {
  .alert-default i {
    font-size: 16px;
  }
}
.alert-default > span {
  padding-left: 9px;
  padding-right: 65px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .alert-default > span {
    padding-left: 25px;
    padding-right: 80px;
  }
}
@media (min-width: 992px) {
  .alert-default > span {
    font-size: 16px !important;
  }
}
.alert-default .close {
  text-shadow: none;
  opacity: 1;
  z-index: 1;
  padding: 0.75rem 0.7rem;
}
@media (min-width: 768px) {
  .alert-default .close {
    padding: 0.75rem 1.25rem;
  }
}
.alert-default .close:hover {
  color: inherit;
}
.alert-default .close:focus {
  outline: 0;
}
.alert-default > span,
.alert-default .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .alert-default > span,
  .alert-default .close {
    font-size: 14px;
  }
}
.alert-default.alert-success i {
  background-color: #60a662;
}
.alert-default.alert-danger i {
  background-color: #e25a5a;
}
.alert-default.alert-primary i {
  background-color: #e9036e;
}
.alert-default.alert-warning i {
  background-color: #fec701;
}
.alert-default.alert-info i {
  background-color: #1ab3e8;
}
.alert-dismissible .close {
  outline: 0;
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border: 0;
}
.accordion .card:first-of-type {
  border: 0;
}
.accordion .card:last-of-type {
  border: 0;
}
.accordion .card {
  border-radius: 0;
  margin-bottom: 0.9375rem;
  background-color: transparent;
}
.accordion .card .card-header {
  padding: 0;
  border-bottom: 1px solid transparent;
  background-color: transparent;
}
.accordion .card .card-header h5 {
  cursor: pointer;
  margin-bottom: 0;
  font-family: montserrat, sans-serif;
  font-weight: 700;
  color: #969696;
  font-size: 13px;
  padding: 0.75rem 2.1875rem 0.75rem 0;
  text-transform: uppercase;
  position: relative;
}
.accordion .card .card-header h5 span {
  display: block;
}
.accordion .card .card-header h5.icon-bg {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
  transition: all 0.3s;
}
.accordion .card .card-header h5.icon-bg:before {
  position: absolute;
  top: 50%;
  font-family: fontawesome;
  font-style: normal;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.125rem 0.187rem;
  transform: translateY(-50%) rotate(0deg);
  content: "\f068";
  right: 0;
  color: #e9036e;
}
.accordion .card .card-header h5.icon-bg.collapsed {
  background-color: transparent;
  color: #969696;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
}
.accordion .card .card-header h5.icon-bg.collapsed:before {
  transform: translateY(-50%) rotate(0deg);
  color: #969696;
  content: "\f067";
}
.accordion .card .card-header h5.icon-bg.collapsed span {
  color: #969696 !important;
}
.accordion .card .card-header h5.icon-bg span {
  color: #e9036e !important;
}
.accordion .card .card-body {
  padding: 1.875rem 0 0;
}
.coming-soon {
  background: no-repeat center top fixed;
  background-size: cover;
}
.coming-soon .coming-soon-logo {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (min-width: 768px) {
  .coming-soon .coming-soon-logo {
    padding: 32px 0;
  }
}
.coming-soon .coming-soon-logo img {
  max-width: 100%;
  outline: 0;
}
.coming-soon .coming-soon-content {
  text-align: center;
}
.coming-soon .coming-soon-content h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .coming-soon .coming-soon-content h1 {
    font-size: 40px;
    margin-bottom: 40px;
  }
}
.coming-soon .coming-soon-content p {
  font-size: 0.875rem;
  color: #a7a7a7;
  margin-bottom: 30px;
}
.coming-soon .coming-soon-count {
  margin-bottom: 35px;
}
.coming-soon-count .timer-body-block {
  display: flex;
  justify-content: center;
  text-align: center;
}
.coming-soon-count .timer-body-block .table-cell {
  margin: 0 4px;
}
@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell {
    margin: 0 12px;
  }
}
@media (min-width: 1200px) {
  .coming-soon-count .timer-body-block .table-cell {
    margin: 0 15px;
  }
}
.coming-soon-count .timer-body-block .table-cell .tab-val {
  display: block;
  width: 65px;
  height: 65px;
  line-height: 65px;
  margin-bottom: 20px;
  font-size: 1.5625rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 2.1875rem;
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .coming-soon-count .timer-body-block .table-cell .tab-val {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
}
.coming-soon-count .timer-body-block .table-cell .tab-unit {
  font-size: 12px;
  text-transform: uppercase;
  color: #a7a7a7;
}
@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell .tab-unit {
    font-size: 14px;
  }
}
.table-dashboard {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
}
@media (min-width: 768px) {
  .table-dashboard {
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .table-dashboard {
    padding: 30px;
  }
}
.table-dashboard h2 {
  font-size: 24px;
}
.table-dashboard .table {
  margin-bottom: 0;
  border: 1px solid #dee2e6;
  border-top: 0;
}
@media (min-width: 768px) {
  .table-dashboard .table {
    border: 0;
  }
}
.table-dashboard .table th,
.table-dashboard .table td {
  padding: 0;
  vertical-align: middle;
  padding: 15px 0;
}
.table-dashboard .table th:first-child,
.table-dashboard .table td:first-child {
  padding-left: 10px;
}
@media (min-width: 768px) {
  .table-dashboard .table th:first-child,
  .table-dashboard .table td:first-child {
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .table-dashboard .table th:first-child,
  .table-dashboard .table td:first-child {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 1200px) {
  .table-dashboard .table th:first-child,
  .table-dashboard .table td:first-child {
    padding-left: 30px;
  }
}
.table-dashboard .table th:last-child,
.table-dashboard .table td:last-child {
  padding-right: 10px;
}
.table-dashboard .table h1,
.table-dashboard .table h2,
.table-dashboard .table h3,
.table-dashboard .table h4,
.table-dashboard .table h5,
.table-dashboard .table h6,
.table-dashboard .table p {
  margin: 0;
}
.table-dashboard .table tr {
  background-color: #fff;
}
.table-dashboard .table .badge {
  width: 55px;
  height: 58px;
  color: #fff;
}
@media (min-width: 992px) {
  .table-dashboard .table .badge {
    margin-right: -1.3pc;
  }
}
@media (min-width: 1200px) {
  .table-dashboard .table .badge {
    margin-right: -1.9pc;
  }
}
.table-dashboard .table .badge span {
  text-transform: capitalize;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
.table-dashboard .table .badge h1,
.table-dashboard .table .badge h3 {
  font-weight: 600;
}
.table-dashboard .table .badge h1 {
  font-size: 22px;
}
.table-dashboard .table .badge h3 {
  font-size: 14px;
}
.table-dashboard .table .badge,
.table-dashboard .table .list-unstyled {
  display: none;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .table-dashboard .table .badge,
  .table-dashboard .table .list-unstyled {
    display: block;
  }
}
@media (min-width: 768px) {
  .table-dashboard .table .list-unstyled {
    border-left: 1px solid #ddd;
    margin-left: 15px;
    padding-left: 15px;
  }
}
.table-dashboard .table .list-unstyled h5,
.table-dashboard .table .list-unstyled h6 {
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.table-dashboard .table .list-unstyled h5 {
  color: #e9036e;
}
.table-dashboard .table .list-unstyled h6 {
  color: #969696;
  margin-bottom: 10px;
}
.table-dashboard .table a {
  font-size: 12px;
  color: #222;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.5s;
}
@media (min-width: 768px) {
  .table-dashboard .table a {
    font-size: 16px;
  }
}
.table-dashboard .table p {
  font-size: 9px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .table-dashboard .table p {
    font-size: 14px;
  }
}
.table-dashboard .table h2,
.table-dashboard .table h4 {
  text-align: right;
}
.table-dashboard .table h2 {
  font-size: 14px;
  color: #e9036e;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .table-dashboard .table h2 {
    font-size: 22px;
    font-weight: 600;
  }
}
.table-dashboard .table h4 {
  font-size: 8px;
  color: #969696;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .table-dashboard .table h4 {
    font-size: 12px;
  }
}
.table-dashboard .table .second-child {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .table-dashboard .table .second-child {
    padding: 32px 0;
    padding-left: 10px;
  }
}
@media (min-width: 992px) {
  .table-dashboard .table .second-child {
    padding-right: 150px;
  }
}
.table-dashboard .table .second-child i {
  width: 45px;
  height: 45px;
  color: #e9036e;
  font-size: 20px;
  line-height: 45px;
  margin-right: 7px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #e9036e;
}
@media (min-width: 768px) {
  .table-dashboard .table .second-child i {
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .table-dashboard .table .second-child i {
    margin-right: 10px;
  }
}
.table-dashboard .table.table-hover tbody tr {
  transition: all 0.5s;
}
html[dir="rtl"] .menuzord-brand {
  float: left;
}
html[dir="rtl"] .menuzord-menu.menuzord-right {
  float: right;
}
html[dir="rtl"] .menuzord .showhide {
  position: absolute;
  right: 0;
}
html[dir="rtl"] .menuzord-menu > li {
  float: left;
}
html[dir="rtl"] .menuzord-menu li ul.dropdown li .indicator::before {
  content: "\f104";
  font-family: fontawesome;
  right: 20px;
  position: absolute;
}
html[dir="rtl"] .menuzord-menu ul.dropdown .indicator i {
  display: none;
}
html[dir="rtl"] .menuzord-menu ul.dropdown li ul.dropdown {
  left: 100%;
}
html[dir="rtl"] .nav-menuzord .nav-item.dropdown .dropdown-menu {
  transform: translate(0, 38px) !important;
}
@media (min-width: 768px) {
  html[dir="rtl"]
    .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 40px !important;
  }
}
@media (min-width: 992px) {
  html[dir="rtl"]
    .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 25px !important;
  }
}
@media (min-width: 768px) {
  html[dir="rtl"]
    .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 60px !important;
  }
}
@media (min-width: 992px) {
  html[dir="rtl"]
    .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 25px !important;
  }
}
html[dir="rtl"] .selectric .button {
  left: auto;
  right: 5px;
}
html[dir="rtl"] .selectric .label {
  margin: 0;
}
html[dir="rtl"] #admin {
  right: 0;
  left: auto !important;
}
html[dir="rtl"] .btn span .fa-angle-right {
  transform: rotate(180deg);
}
html[dir="rtl"] .btn .fa-long-arrow-right {
  transform: rotate(180deg);
}
html[dir="rtl"] .gallery-slider .slick-next {
  left: -60px;
}
@media (min-width: 992px) {
  html[dir="rtl"] .gallery-slider .slick-next {
    left: -80px;
  }
}
html[dir="rtl"] .gallery-slider .slick-prev {
  right: -60px;
  left: auto;
}
@media (min-width: 992px) {
  html[dir="rtl"] .gallery-slider .slick-prev {
    right: -80px;
  }
}
html[dir="rtl"] .brand-slider .slick-prev {
  right: 0;
  left: auto;
}
html[dir="rtl"] .brand-slider .slick-next {
  right: auto;
}
html[dir="rtl"] .noUi-horizontal .noUi-handle {
  left: 0;
  right: auto;
}
html[dir="rtl"] .noUi-horizontal .noUi-handle.noUi-handle-lower,
html[dir="rtl"] .noUi-horizontal .noUi-handle.noUi-handle-upper {
  left: -15px !important;
  right: auto !important;
}
html[dir="rtl"] .noUi-horizontal .noUi-handle.noUi-handle-upper {
  left: 0 !important;
}
html[dir="rtl"] .package-slider.owl-theme .owl-nav .owl-next {
  left: 20px;
}
html[dir="rtl"] .package-slider.owl-theme .owl-nav .owl-prev {
  right: 20px;
  left: auto;
}
html[dir="rtl"] .gallery-photo-slider.owl-theme .owl-nav .owl-prev {
  right: 20px;
  left: auto;
}
html[dir="rtl"] .gallery-photo-slider.owl-theme .owl-nav .owl-next {
  left: 20px;
}
html[dir="rtl"] .pagination .page-item .page-link i {
  transform: rotate(180deg);
}
html[dir="rtl"] .copyright p a {
  float: left;
  margin-right: 5px;
}
img {
  transition: filter 0.3s;
}
img.lazyestload {
 width: 100%;
  /* filter: blur(8px); */
}
.element-right-sidebar {
  min-width: 215px;
}
.element-right-sidebar.sidebar-fixed {
  position: fixed;
  top: 110px;
  bottom: 0;
}
.element-right-sidebar.right-sidebar-absolute {
  position: absolute;
  top: auto;
  bottom: 0;
}
.footer .footer-bg-color,
.footer-2 .footer-bg-color {
  background-color: #262a2e;
}
.footer .title-tag,
.footer-2 .title-tag {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .footer .title-tag,
  .footer-2 .title-tag {
    margin-bottom: 40px;
  }
}
.footer .title-tag h6,
.footer-2 .title-tag h6 {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
}
.footer .list-unstyled .media i,
.footer-2 .list-unstyled .media i {
  color: #969696;
}
.footer .list-unstyled .media .media-body a,
.footer-2 .list-unstyled .media .media-body a {
  color: #969696;
  font-size: 0.875rem;
  transition: all 0.3s;
}
.footer .list-unstyled .media .media-body a:hover,
.footer-2 .list-unstyled .media .media-body a:hover {
  color: #e9036e;
}
.footer .gallery > .col-4,
.footer-2 .gallery > .col-4 {
  padding: 0 0 0 15px;
}
.footer .copyright,
.footer-2 .copyright {
  background-color: #232629;
}
.footer .copyright p,
.footer-2 .copyright p {
  font-size: 13px;
  text-align: center;
}
@media (min-width: 992px) {
  .footer .copyright p,
  .footer-2 .copyright p {
    font-size: 14px;
  }
}
.footer .copyright .list-inline a,
.footer-2 .copyright .list-inline a {
  font-size: 13px;
  color: #969696;
  transition: all 0.3s;
}
@media (min-width: 992px) {
  .footer .copyright .list-inline a,
  .footer-2 .copyright .list-inline a {
    font-size: 14px;
  }
}
.footer .copyright .list-inline a:hover,
.footer-2 .copyright .list-inline a:hover {
  color: #e9036e;
}
.footer-2 h6 {
  font-weight: 400;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}
@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}
@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}
@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}
@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}
@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}
@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}
@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}
@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}
@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}
@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}
@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}
@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.menuzord,
.menuzord * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.menuzord {
  width: 100%;
  padding: 0 30px;
  float: left;
  position: relative;
  background: #fff;
}
.menuzord a {
  -o-transition: color 0.3s linear, background 0.3s linear;
  -moz-transition: color 0.3s linear, background 0.3s linear;
  -webkit-transition: color 0.3s linear, background 0.3s linear;
  transition: color 0.3s linear, background 0.3s linear;
}
.menuzord-menu.scrollable > .scrollable-fix {
  display: none;
}
.menuzord-brand {
  margin: 18px 30px 0 0;
  float: left;
  color: #666;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  cursor: pointer;
}
.menuzord-menu {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}
.menuzord-menu.menuzord-right {
  float: right;
}
.menuzord-menu > li {
  display: inline-block;
  float: left;
}
.menuzord-menu > li > a {
  padding: 26px 16px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #888;
  outline: 0;
  line-height: 1.42857143;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.menuzord-menu > li.active > a,
.menuzord-menu > li:hover > a {
  color: #333;
}
.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 99;
  min-width: 180px;
  white-space: nowrap;
  background: #fff;
}
.menuzord-menu ul.dropdown li ul.dropdown {
  left: 100%;
}
.menuzord-menu ul.dropdown li {
  clear: both;
  width: 100%;
  border: 0;
  font-size: 12px;
  position: relative;
}
.menuzord-menu ul.dropdown li a {
  width: 100%;
  background: 0 0;
  padding: 10px 25px 10px 22px;
  color: #888;
  text-decoration: none;
  display: inline-block;
  float: left;
  clear: both;
  position: relative;
  outline: 0;
  -o-transition: padding 0.2s linear;
  -moz-transition: padding 0.2s linear;
  -webkit-transition: padding 0.2s linear;
  transition: padding 0.2s linear;
}
.menuzord-menu ul.dropdown li:hover > a {
  padding-left: 28px;
  padding-right: 19px;
  color: #333;
}
.menuzord-menu ul.dropdown.dropdown-left {
  left: none;
}
.menuzord-menu ul.dropdown li ul.dropdown.dropdown-left {
  left: -100%;
}
.menuzord-menu li .indicator {
  margin-left: 3px;
}
.menuzord-menu li ul.dropdown li .indicator {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 15px;
}
.menuzord-menu > li > .megamenu {
  width: 100%;
  padding: 20px 30px;
  left: 0;
  position: absolute;
  display: none;
  z-index: 99;
  border-top: solid 1px #f0f0f0;
  background: #fff;
}
.menuzord-menu > li > .megamenu.megamenu-half-width {
  width: 50%;
  left: auto;
}
.menuzord-menu > li > .megamenu.megamenu-quarter-width {
  width: 25%;
  left: auto;
}
.menuzord-menu > li > .megamenu .megamenu-row {
  width: 100%;
  margin-top: 15px;
}
.menuzord-menu > li > .megamenu .megamenu-row:first-child {
  margin-top: 0;
}
.menuzord-menu > li > .megamenu .megamenu-row:before,
.menuzord-menu > li > .megamenu .megamenu-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.menuzord-menu > li > .megamenu .megamenu-row:after {
  clear: both;
}
.menuzord-menu > li > .megamenu .megamenu-row .col1,
.menuzord-menu > li > .megamenu .megamenu-row .col2,
.menuzord-menu > li > .megamenu .megamenu-row .col3,
.menuzord-menu > li > .megamenu .megamenu-row .col4,
.menuzord-menu > li > .megamenu .megamenu-row .col5,
.menuzord-menu > li > .megamenu .megamenu-row .col6,
.menuzord-menu > li > .megamenu .megamenu-row .col7,
.menuzord-menu > li > .megamenu .megamenu-row .col8,
.menuzord-menu > li > .megamenu .megamenu-row .col9,
.menuzord-menu > li > .megamenu .megamenu-row .col10,
.menuzord-menu > li > .megamenu .megamenu-row .col11,
.menuzord-menu > li > .megamenu .megamenu-row .col12 {
  display: block;
  min-height: 20px;
  float: left;
  margin-left: 3%;
}
.menuzord-menu > li > .megamenu .megamenu-row [class*="col"]:first-child {
  margin-left: 0;
}
.menuzord-menu > li > .megamenu .megamenu-row .col1 {
  width: 5.583333333333%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col2 {
  width: 14.166666666666%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col3 {
  width: 22.75%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col4 {
  width: 31.333333333333%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col5 {
  width: 39.916666666667%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col6 {
  width: 48.5%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col7 {
  width: 57.083333333333%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col8 {
  width: 65.666666666667%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col9 {
  width: 74.25%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col10 {
  width: 82.833333333334%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col11 {
  width: 91.416666666667%;
}
.menuzord-menu > li > .megamenu .megamenu-row .col12 {
  width: 100%;
}
.menuzord .showhide {
  width: 60px;
  height: 46px;
  padding: 13px 0 0;
  display: none;
  float: right;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.menuzord .showhide em {
  width: 20px;
  height: 3px;
  margin: 3.5px 20px 0;
  float: right;
  background: #777;
}
.menuzord-menu > li > a > i {
  margin: 0 5px 0 0;
  font-size: 18px;
  float: left;
  line-height: 20px;
}
.menuzord-menu > li.search form {
  float: left;
  padding: 22px 16px 17px;
}
.menuzord-menu > li.search form input[type="text"] {
  width: 160px;
  padding: 5px 10px;
  border: none;
  background: #f0f0f0;
  border-radius: 2px;
  outline: 0;
  -o-transition: width 1s ease-out;
  -moz-transition: width 1s ease-out;
  -webkit-transition: width 1s ease-out;
  transition: width 1s ease-out;
}
.menuzord-menu > li.search form:hover input[type="text"] {
  width: 160px;
}
.menuzord-tabs {
  width: 100%;
  float: left;
}
.menuzord-tabs-nav {
  width: 20%;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}
.menuzord-tabs-nav > li > a {
  width: 100%;
  padding: 7px 16px;
  float: left;
  font-size: 13px;
  text-decoration: none;
  color: #666;
  border: solid 1px #f0f0f0;
  outline: 0;
}
.menuzord-tabs-nav li.active a,
.menuzord-tabs-nav li:hover a {
  background: #f0f0f0;
}
.menuzord-tabs-content {
  width: 80%;
  min-height: 30px;
  padding: 20px;
  float: right;
  display: none;
  font-size: 13px;
  border: solid 1px #f0f0f0;
}
.menuzord-tabs-content.active {
  display: block;
}
.menuzord.blue .menuzord-menu > li.active > a,
.menuzord.blue .menuzord-menu > li:hover > a,
.menuzord.blue .menuzord-menu ul.dropdown li:hover > a {
  color: #4a89dc;
}
.menuzord.green .menuzord-menu > li.active > a,
.menuzord.green .menuzord-menu > li:hover > a,
.menuzord.green .menuzord-menu ul.dropdown li:hover > a {
  color: #37bc9b;
}
.menuzord.red .menuzord-menu > li.active > a,
.menuzord.red .menuzord-menu > li:hover > a,
.menuzord.red .menuzord-menu ul.dropdown li:hover > a {
  color: #e9573f;
}
.menuzord.orange .menuzord-menu > li.active > a,
.menuzord.orange .menuzord-menu > li:hover > a,
.menuzord.orange .menuzord-menu ul.dropdown li:hover > a {
  color: #ff670f;
}
.menuzord.yellow .menuzord-menu > li.active > a,
.menuzord.yellow .menuzord-menu > li:hover > a,
.menuzord.yellow .menuzord-menu ul.dropdown li:hover > a {
  color: #f6bb42;
}
.menuzord.purple .menuzord-menu > li.active > a,
.menuzord.purple .menuzord-menu > li:hover > a,
.menuzord.purple .menuzord-menu ul.dropdown li:hover > a {
  color: #967adc;
}
.menuzord.pink .menuzord-menu > li.active > a,
.menuzord.pink .menuzord-menu > li:hover > a,
.menuzord.pink .menuzord-menu ul.dropdown li:hover > a {
  color: #d770ad;
}
@media (max-width: 768px) {
  .menuzord-responsive.menuzord {
    padding: 0;
  }
  .menuzord-responsive .menuzord-brand {
    margin: 8px 30px 10px 20px;
    font-size: 20px;
    line-height: 1.55;
  }
  .menuzord-responsive .showhide {
    margin: 0;
  }
  .menuzord-responsive .menuzord-menu {
    width: 100%;
    float: left !important;
  }
  .menuzord-responsive .menuzord-menu.scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .menuzord-responsive .menuzord-menu.scrollable > .scrollable-fix {
    display: block;
  }
  .menuzord-responsive .menuzord-menu > li {
    width: 100%;
    display: block;
    position: relative;
  }
  .menuzord-responsive .menuzord-menu > li > a {
    width: 100%;
    padding: 12px 20px;
    display: block;
    border-bottom: solid 1px #f0f0f0;
  }
  .menuzord-responsive .menuzord-menu > li:first-child > a {
    border-top: solid 1px #f0f0f0;
  }
  .menuzord-responsive .menuzord-menu ul.dropdown,
  .menuzord-responsive .menuzord-menu ul.dropdown li ul.dropdown {
    width: 100%;
    left: 0;
    position: static;
    border: none;
    background: #fff;
    float: left;
  }
  .menuzord-responsive .menuzord-menu ul.dropdown li {
    position: relative;
  }
  .menuzord-responsive .menuzord-menu ul.dropdown li a {
    border-bottom: solid 1px #f0f0f0;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 40px !important;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 60px !important;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > a {
    padding-left: 80px !important;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li:hover
    > a {
    padding-left: 50px !important;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li:hover
    > a {
    padding-left: 70px !important;
  }
  .menuzord-responsive
    .menuzord-menu.menuzord-indented
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li
    > ul.dropdown
    > li:hover
    > a {
    padding-left: 90px !important;
  }
  .menuzord-responsive .menuzord-menu li .indicator {
    width: 60px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 22px;
    text-align: center;
    line-height: 43px;
    border-left: solid 1px #f5f5f5;
  }
  .menuzord-responsive .menuzord-menu li ul.dropdown li .indicator {
    height: 38px;
    right: 0;
    top: 0;
    font-size: 18px;
    line-height: 36px;
  }
  .menuzord-responsive .menuzord-menu > li > .megamenu {
    width: 100% !important;
    left: 0 !important;
    position: static;
    border-top: none;
    border-bottom: solid 1px #f0f0f0;
  }
  .menuzord-responsive
    .menuzord-menu
    > li
    > .megamenu
    .megamenu-row
    [class*="col"] {
    float: none;
    display: block;
    width: 100% !important;
    margin-left: 0;
    margin-top: 15px;
  }
  .menuzord-responsive
    .menuzord-menu
    > li
    > .megamenu
    .megamenu-row:first-child
    [class*="col"]:first-child {
    margin-top: 0;
  }
  .menuzord-responsive .menuzord-menu > li > .megamenu .megamenu-row {
    margin-top: 0;
  }
  .menuzord-responsive .menuzord-menu > li.search form {
    width: 100%;
    margin: 4px 0;
    padding: 10px 16px !important;
  }
  .menuzord-responsive .menuzord-menu > li.search form input[type="text"] {
    width: 100%;
  }
  .menuzord-responsive .menuzord-tabs-nav {
    width: 100%;
  }
  .menuzord-responsive .menuzord-tabs-content {
    width: 100%;
  }
}

.zoom-in {
  -webkit-animation: zoomIn 400ms ease both;
  -moz-animation: zoomIn 400ms ease both;
  -o-animation: zoomIn 400ms ease both;
  animation: zoomIn 400ms ease both;
}
@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes zoomIn {
  0% {
    -moz-transform: scale(1.5);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes zoomIn {
  0% {
    opacity: 0;
    -o-transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.zoom-out {
  -webkit-animation: zoomOut 400ms ease both;
  -moz-animation: zoomOut 400ms ease both;
  -o-animation: zoomOut 400ms ease both;
  animation: zoomOut 400ms ease both;
}
@-webkit-keyframes zoomOut {
  0% {
    -webkit-transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes zoomOut {
  0% {
    -moz-transform: scale(0.6);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes zoomOut {
  0% {
    -o-transform: scale(0.6);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
.drop-up {
  -webkit-animation: drop-up 400ms ease both;
  -moz-animation: drop-up 400ms ease both;
  -o-animation: drop-up 400ms ease both;
  animation: drop-up 400ms ease both;
}
@-webkit-keyframes drop-up {
  0% {
    -webkit-transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes drop-up {
  0% {
    -moz-transform: translateY(100px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes drop-up {
  0% {
    -o-transform: translateY(100px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes drop-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.drop-left {
  -webkit-animation: drop-left 400ms ease both;
  -moz-animation: drop-left 400ms ease both;
  -o-animation: drop-left 400ms ease both;
  animation: drop-left 400ms ease both;
}
@-webkit-keyframes drop-left {
  0% {
    -webkit-transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes drop-left {
  0% {
    -moz-transform: translateX(-100px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes drop-left {
  0% {
    -o-transform: translateX(-100px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes drop-left {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation: swing 400ms ease-out both;
  -moz-animation: swing 400ms ease-out both;
  -o-animation: swing 400ms ease-out both;
  animation: swing 400ms ease-out both;
}
@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }
  40% {
    -moz-transform: rotate(-10deg);
  }
  60% {
    -moz-transform: rotate(5deg);
  }
  80% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }
  40% {
    -o-transform: rotate(-10deg);
  }
  60% {
    -o-transform: rotate(5deg);
  }
  80% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}
@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.flip {
  -webkit-animation: flip 700ms ease both;
  -moz-animation: flip 700ms ease both;
  -o-animation: flip 700ms ease both;
  animation: flip 700ms ease both;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
.roll-in {
  -webkit-animation: roll-in 400ms ease both;
  -moz-animation: roll-in 400ms ease both;
  -o-animation: roll-in 400ms ease both;
  animation: roll-in 400ms ease both;
}
@-webkit-keyframes roll-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-15deg);
    transform: translateX(-100%) rotate(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes roll-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-15deg);
    -ms-transform: translateX(-100%) rotate(-15deg);
    transform: translateX(-100%) rotate(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
.stretch {
  -webkit-animation: stretch 500ms ease both;
  -moz-animation: stretch 500ms ease both;
  -o-animation: stretch 500ms ease both;
  animation: stretch 500ms ease both;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}
@keyframes stretch {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes stretch {
  0% {
    -webkit-transform: scaleX(0.3);
  }
  40% {
    -webkit-transform: scaleX(1.02);
  }
  60% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}
@-o-keyframes stretch {
  0% {
    -o-transform: scaleX(0.3);
  }
  40% {
    -o-transform: scaleX(1.02);
  }
  60% {
    -o-transform: scaleX(0.98);
  }
  80% {
    -o-transform: scaleX(1.01);
  }
  100% {
    -o-transform: scaleX(0.98);
  }
  80% {
    -o-transform: scaleX(1.01);
  }
  100% {
    -o-transform: scaleX(1);
  }
}

.fancybox-enabled {
  overflow: hidden;
}
.fancybox-enabled body {
  overflow: visible;
  height: 100%;
}
.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}
.fancybox-containerPublic {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.fancybox-containerPublic ~ .fancybox-containerPublic {
  z-index: 99992;
}
.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}
.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
.fancybox-caption-wrap,
.fancybox-infobar,
.fancybox-toolbar {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0s linear 0.25s;
  box-sizing: border-box;
}
.fancybox-show-caption .fancybox-caption-wrap,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s, visibility 0s;
}
.fancybox-infobar {
  top: 0;
  left: 50%;
  margin-left: -79px;
}
.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}
.fancybox-toolbar {
  top: 0;
  right: 0;
}
.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translateZ(0);
}
.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.fancybox-slide:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block;
}
.fancybox-slide--image {
  overflow: visible;
}
.fancybox-slide--image:before {
  display: none;
}
.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000;
}
.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #e5e3df;
}
.fancybox-slide--next {
  z-index: 99995;
}
.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}
.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: zoom-out;
}
.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: zoom-in;
}
.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab;
}
.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
}
.fancybox-spaceball {
  z-index: 1;
}
.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff;
}
.fancybox-iframe {
  display: block;
  padding: 0;
  border: 0;
  height: 100%;
}
.fancybox-error,
.fancybox-iframe {
  margin: 0;
  width: 100%;
  background: #fff;
}
.fancybox-error {
  padding: 40px;
  max-width: 380px;
  cursor: default;
}
.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px Helvetica Neue, Helvetica, Arial, sans-serif;
}
.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}
.fancybox-close-small:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background 0.25s;
  box-sizing: border-box;
  z-index: 2;
}
.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}
.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}
.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -44px;
}
.fancybox-slide--iframe .fancybox-close-small:after {
  background: transparent;
  font-size: 35px;
  color: #aaa;
}
.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff;
}
.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0;
  background: linear-gradient(
    180deg,
    transparent 0,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0.2) 40%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0.8)
  );
  pointer-events: none;
}
.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}
.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all;
}
.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}
.fancybox-button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: transparent;
  color: #ddd;
  border-radius: 0;
  cursor: pointer;
  vertical-align: top;
  outline: none;
}
.fancybox-button[disabled] {
  cursor: default;
  pointer-events: none;
}
.fancybox-button,
.fancybox-infobar__body {
  background: rgba(30, 30, 30, 0.6);
}
.fancybox-button:hover:not([disabled]) {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
}
.fancybox-button:after,
.fancybox-button:before {
  content: "";
  pointer-events: none;
  position: absolute;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block;
}
.fancybox-button[disabled]:after,
.fancybox-button[disabled]:before {
  opacity: 0.3;
}
.fancybox-button--left:after,
.fancybox-button--right:after {
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
}
.fancybox-button--left:after {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.fancybox-button--right:after {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.fancybox-button--left {
  border-bottom-left-radius: 5px;
}
.fancybox-button--right {
  border-bottom-right-radius: 5px;
}
.fancybox-button--close:after,
.fancybox-button--close:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}
.fancybox-button--close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.fancybox-button--close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0;
  height: 100px;
  width: 54px;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.25s;
}
.fancybox-arrow--right {
  right: 0;
}
.fancybox-arrow--left {
  left: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.fancybox-arrow--left:after,
.fancybox-arrow--right:after {
  left: 0;
}
.fancybox-show-nav .fancybox-arrow {
  opacity: 0.6;
}
.fancybox-show-nav .fancybox-arrow[disabled] {
  opacity: 0.3;
}
.fancybox-loading {
  border: 6px solid hsla(0, 0%, 39%, 0.4);
  border-top: 6px solid hsla(0, 0%, 100%, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: a 0.8s infinite linear;
  animation: a 0.8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}
@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}
.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}
.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0;
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}
.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
  opacity: 0;
}
.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  opacity: 0;
}
.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0;
}
.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0;
}
.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  opacity: 1;
}
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}
.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}
.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}
@media (max-width: 800px) {
  .fancybox-infobar {
    left: 0;
    margin-left: 0;
  }
  .fancybox-button--left,
  .fancybox-button--right {
    display: none !important;
  }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}
.fancybox-button--fullscreen:before {
  width: 15px;
  height: 11px;
  left: calc(50% - 7px);
  top: calc(50% - 6px);
  border: 2px solid;
  background: none;
}
.fancybox-button--pause:before,
.fancybox-button--play:before {
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  background: transparent;
}
.fancybox-button--play:before {
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
}
.fancybox-button--pause:before {
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px;
}
.fancybox-button--thumbs,
.fancybox-thumbs {
  display: none;
}
@media (min-width: 800px) {
  .fancybox-button--thumbs {
    display: inline-block;
  }
  .fancybox-button--thumbs span {
    font-size: 23px;
  }
  .fancybox-button--thumbs:before {
    width: 3px;
    height: 3px;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, inset 0 0 0 32px, -4px 0 0,
      4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0;
  }
  .fancybox-thumbs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 220px;
    margin: 0;
    padding: 5px 5px 0 0;
    background: #fff;
    word-break: normal;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    z-index: 99995;
  }
  .fancybox-show-thumbs .fancybox-thumbs {
    display: block;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 220px;
  }
  .fancybox-thumbs > ul {
    list-style: none;
    position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0;
  }
  .fancybox-thumbs > ul > li {
    float: left;
    overflow: hidden;
    max-width: 50%;
    padding: 0;
    margin: 0;
    width: 105px;
    height: 75px;
    position: relative;
    cursor: pointer;
    outline: none;
    border: 5px solid transparent;
    border-top-width: 0;
    border-right-width: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box;
  }
  li.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 0.1);
  }
  .fancybox-thumbs > ul > li > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .fancybox-thumbs > ul > li:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    border: 4px solid #4ea7f9;
    z-index: 99991;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
    opacity: 1;
  }
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}
.selectric-responsive {
  width: 100%;
}
.selectric {
  border: 1px solid #e0e0e0;
  border-bottom-width: 2px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 30px 0 0;
  padding: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #444;
  min-height: 18px;
}

/* @media \0screen\, screen\9 {
  .selectric .button {
    color: #ddd;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
} */
.selectric-focus .selectric {
  border-color: #aaa;
}
.selectric-hover .selectric {
  border-color: #ccc;
}
.selectric-hover .selectric .button {
  color: #888;
}
.selectric-hover .selectric .button:after {
  border-top-color: #888;
}
.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric {
  border-color: #ccc;
  background: #f0f0f0;
  margin-top: 1px;
  border-bottom-width: 1px;
}
.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}
.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0a !important;
  background: 0 0 !important;
}
.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fefefe;
  border: 1px solid #ccc;
  z-index: -1;
  box-shadow: 0 0 10px -6px, inset 0 0 0 1px #fff;
  margin: 8px 0;
  padding: 10px 0;
  border-radius: 4px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-items:before,
.selectric-items:after {
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #bbb;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}
.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #fff;
}
.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #bbb;
  margin-top: 0;
}
.selectric-above .selectric-items:after {
  border-top-color: #fff;
  margin-top: -1px;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 6px 30px 6px 10px;
  color: #666;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.selectric-items li.selected {
  background: #e0e0e0;
  color: #444;
}
.selectric-items li.highlighted {
  background: #d0d0d0;
  color: #444;
}
.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}
.selectric-items li:hover {
  background: #f0f0f0;
  color: #444;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: 0 0 !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: 700;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: 0 0;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}
.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 10px;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 10px;
}
.daterangepicker.drop-up {
  margin-top: -7px;
}
.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}
.daterangepicker.single .drp-selected {
  display: none;
}
.daterangepicker.show-calendar .drp-calendar {
  display: block;
}
.daterangepicker.show-calendar .drp-buttons {
  display: block;
}
.daterangepicker.auto-apply .drp-buttons {
  display: none;
}
.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}
.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
  padding: 8px;
}
.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}
.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}
.daterangepicker th.month {
  width: auto;
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0;
  line-height: 30px;
  position: relative;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}
.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}
.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}
.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background-color: #eee;
}
.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single.ltr .ranges,
  .daterangepicker.single.ltr .drp-calendar {
    float: left;
  }
  .daterangepicker.single.rtl .ranges,
  .daterangepicker.single.rtl .drp-calendar {
    float: right;
  }
  .daterangepicker.ltr {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker.ltr .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.ltr .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.ltr .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker.ltr .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.ltr .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker.ltr .ranges,
  .daterangepicker.ltr .drp-calendar {
    float: left;
  }
  .daterangepicker.rtl {
    direction: rtl;
    text-align: right;
  }
  .daterangepicker.rtl .drp-calendar.left {
    clear: right;
    margin-left: 0;
  }
  .daterangepicker.rtl .drp-calendar.left .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.rtl .drp-calendar.right {
    margin-right: 0;
  }
  .daterangepicker.rtl .drp-calendar.right .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.rtl .drp-calendar.left .calendar-table {
    padding-left: 12px;
  }
  .daterangepicker.rtl .ranges,
  .daterangepicker.rtl .drp-calendar {
    text-align: right;
    float: right;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker.ltr .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  /* display: none; */
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
.tho-option {
  direction: ltr;
}
.tho-option-switcher-btn {
  top: 101px;
  position: fixed;
  background: #363636;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: -60px;
  min-width: 60px;
}
.tho-icon-option i {
  display: inline-block;
  cursor: pointer;
  padding: 25px 30px;
  position: relative;
  transform: translateX(0px) scale(1);
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  margin-top: 5px;
}
.tho-setting-icon {
  position: relative;
  /* background-image: url(taf-settings.svg); */
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: center center;
}
.tho-icon-option.tho-cross .tho-setting-icon {
  /* background-image: url(taf-tiems.svg); */
}
.tho-option-switcher {
  right: 0;
  top: 0;
  color: #fff;
  width: 315px;
  display: block;
  z-index: 555555;
  position: fixed;
  background: #202020;
  border-radius: 0;
  height: 100%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tho-option-visible {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.tho-option-switcher .tho-option-switcher-heading {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}
.tho-subtitle {
  color: #fff;
  font-size: 16px;
  opacity: 0.3;
  display: block;
  margin-bottom: 15px;
  margin-top: 10px;
}
.tho-option-switcher .tho-btn {
  color: #ececec;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 200;
  border: solid 1px #ececec;
  border-radius: 2px !important;
  padding: 4px 10px;
  width: 48%;
  text-align: center;
  margin-right: 10px;
  text-decoration: none;
  line-height: 21px;
}
.tho-option-switcher .tho-btn.tho-active-switcher-btn,
.tho-option-switcher .tho-btn.tho-active-switcher-btn {
  background-color: #ececec;
  color: #000;
  text-decoration: none;
}
.tho-option-swticher-header {
  padding: 10px 15px;
  border-radius: 4px 4px 0 0 !important;
  background-color: #363636;
  width: 260px;
  display: block;
  margin: 20px auto;
}
.tho-option-swticher-header .option-switcher-heading {
  margin: 0;
  font-size: 16px;
}
.tho-option-swticher-body .no-col-space {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tho-option-swticher-body hr {
  margin: 11px 0;
  border-color: #454545;
}
.tho-option-swticher-body {
  padding: 15px;
  margin: 0 auto;
  width: 260px;
  background: #454545;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.tho-option-switcher .tho-list-unstyled {
  padding-bottom: 10px;
  padding-left: 0;
  list-style: none;
}
.tho-option-switcher li {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: #c00;
  display: inline-block;
  border-radius: 10% !important;
  border: solid 2px #909090;
  margin-right: 6px;
}
.tho-option-switcher li:last-child {
  margin-right: 0;
}
.tho-option-switcher li:hover,
.tho-option-switcher li.theme-active,
.tho-option-switcher li.pattern-active {
  border: solid 2px #fff;
}
.tho-option-switcher li.theme-default {
  background: #e9036e;
}
.tho-option-switcher li.theme-color1 {
  background: #00cd96;
}
.tho-option-switcher li.theme-color2 {
  background: #01ace4;
}
.tho-option-switcher li.theme-color3 {
  background: #ee4735;
}
.tho-option-switcher li.theme-color4 {
  background: #8b62ff;
}
.tho-option-switcher h3 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 600;
}
.tho-option-switcher .body-background {
  margin-top: 25px;
}
.tho-option-switcher li.pattern-default {
  /* background: url(pattern1.png) center repeat; */
}
.tho-option-switcher li.pattern1 {
  /* background: url(pattern2.png) center repeat; */
}
.tho-option-switcher li.pattern2 {
  /* background: url(pattern3.png) center repeat; */
}
.tho-option-switcher li.pattern3 {
  /* background: url(pattern4.png) center repeat; */
}
.tho-option-switcher li.pattern4 {
  /* background: url(pattern5.png) center repeat; */
}
.tho-option-switcher li.pattern5 {
  /* background: url(pattern5.png) center repeat; */
}

@media (max-width: 992px) {
  .headers .headerDesktop {
    display: none;
  }
  
  .headers .headerMovil {
    display: block;
  }
}

@media (min-width: 992px) {
  .headers .headerDesktop {
    display: block;
  }
  
  .headers .headerMovil {
    display: none;
  }
}